import { derived, writable } from "svelte/store";
import translations from "./translations";

export const locale = writable("en");
export const locales = Object.keys(translations);

function translate(
	locale: string,
	key: string,
	vars?: { [name: string]: string },
	fallbackText?: string
): string {
	if (!key) {
		console.warn("No key provided to $t()");
		return fallbackText || "";
	}
	if (locales.indexOf(locale) === -1) {
		console.warn(`Sorry, locale "${locale}" does not exist`);
		locale = "en";
	}
	// Grab the translation from the translations object.
	let text = translations[locale][key];

	if (!text) {
		console.warn(
			`Translation not found for locale "${locale}", fallback to locale "en"`
		);
		text = translations["en"][key];
		if (!text) {
			console.warn("Fallback to given fallback text");
			return fallbackText || "";
		}
	}

	// Ability to pass varriable with localize text
	// Replace any passed in variables in the translation text.
	if (vars) {
		const varsObjKeys = Object.keys(vars);
		varsObjKeys.map(k => {
			const regex = new RegExp(`{{${k}}}`, "g");
			text = text.replace(regex, vars[k]);
		});
	}
	return text;
}

export const t = derived(locale, $locale => {
	return (key: string, fallbackText?: string, vars = {}) => {
		return translate($locale, key, vars, fallbackText);
	};
});

export const updateLocaleLanguage = (configLocaleLang: string) => {
	const documentLang = document.documentElement.lang;
	const updateLocale = (lang: string) => {
		lang = lang.split("-")[0];
		const supportedLangs = Object.keys(translations);
		if (supportedLangs.indexOf(lang) > -1) {
			locale.set(lang);
			return;
		}
		locale.set(configLocaleLang);
	};
	updateLocale(documentLang);
	langchangObserve(document.documentElement, updateLocale);
};

// Observer for document language change
const langchangObserve = (
	target: HTMLElement,
	updateLocaleCallback: (lang: string) => void
) => {
	const mutationCallback: MutationCallback = mutationList => {
		for (const mutation of mutationList) {
			if (mutation.target === target && mutation.attributeName === "lang") {
				updateLocaleCallback((mutation.target as HTMLElement).lang);
			}
		}
	};
	const config = { attributes: true };
	const observer = new MutationObserver(mutationCallback);
	observer.observe(target, config);
};
