import { ITawkAPI } from "../../types/externalLibraries";

const upsyCheckIfTawkLoaded = (callback: (TawkAPI: ITawkAPI) => void) => {
	if (!window.Tawk_API) setTimeout(() => upsyCheckIfTawkLoaded(callback), 1000);
	else callback(window.Tawk_API);
};

const upsyCheckIfTawkMinimized = (TawkAPI: ITawkAPI, callback: () => void) => {
	if (
		window.Tawk_API &&
		window.Tawk_API.isChatMinimized &&
		!window.Tawk_API?.isChatMinimized()
	)
		setTimeout(() => upsyCheckIfTawkMinimized(TawkAPI, callback), 1000);
	else callback();
};

const openTawkChat = (TawkAPI: ITawkAPI, closeWidget: () => void) => {
	window.Tawk_API?.showWidget && window.Tawk_API.showWidget();
	window.Tawk_API?.maximize && window.Tawk_API.maximize();
	closeWidget();
	setTimeout(
		() => upsyCheckIfTawkMinimized(TawkAPI, () => closeTawkChat(TawkAPI)),
		2000
	);
};

const whenTawkLoaded = (TawkAPI: ITawkAPI) => {
	TawkAPI.onLoad = () => {
		closeTawkChat(TawkAPI);
	};
	closeTawkChat(TawkAPI);
};

const closeTawkChat = (TawkAPI: ITawkAPI) => {
	TawkAPI.hideWidget && TawkAPI.hideWidget();
	TawkAPI.minimize && TawkAPI.minimize();
};

export const openChat = (closeWidget: () => void) => {
	upsyCheckIfTawkLoaded((TawkAPI: ITawkAPI) => openTawkChat(TawkAPI, closeWidget));
};

export const initializeChat = () => {
	upsyCheckIfTawkLoaded(whenTawkLoaded);
};
