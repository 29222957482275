import App from "../App.svelte";
import { postInitEvent, postPurchaseEvent } from "../utils/event";
import { IConfigObjectResponse } from "../types/sdk";
import { getEnvironmentEndpoints } from "../utils/getEnvironmentEndpoints";
import {
	getStoreConfigsApiURL,
	getStaticCSSURL,
	getUrlByVersion
} from "../utils/getUpsyUrls";
import { removeWidget } from "../utils/removeWidget";
import { loadUpsyBranch } from "./loadUpsyBranch";
import { isUpsyHideOnThisUrl } from "../utils/getFlowFromUrl";
import { activePage } from "../stores/activePage";
import { getPageType, pageTypeEnum } from "../utils/pageType";
import { cookies } from "../utils/cookieManager";
import {
	STORAGE_UPSY_CART_KEY,
	UPSY_PURCHASE_COMPLETED_COOKIE_KEY,
	UPSY_TEST_MODE_COOKIE_KEY,
	UPSY_TEST_MODE_URL_KEY
} from "../constants";
import { updateLocaleLanguage } from "../i18n/i18n";
import { storeConfig } from "../stores/storeConfig";
import { getOrder } from "../utils/cart";
import { setStorage } from "../utils/storage";
import { isSdkLoaded } from "../stores/sdkLoaded";
import { configureAbTest, checkIsAbTestEnabled, sendAbTestEvents } from "../utils/abTest";
import { getUpsySessionId, setUpsySessionId } from "../utils/session";

const getEnvironment = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const upsyBranch = urlParams.get("upsyBranch");
	return upsyBranch?.trim() || null;
};

const loadConfig = async (dataUrl: string): Promise<IConfigObjectResponse> => {
	const response = await fetch(dataUrl);
	if (response.status !== 200) {
		const errorMessage = `Could not fetch Upsy configuration: response status ${response.status}`;
		console.error(errorMessage);
		throw Error(errorMessage);
	}
	return (await response.json()) as unknown as IConfigObjectResponse;
};

const initialize = async (customerId: string, presetEnvironment = "") => {
	//Remove existing widgets if exist
	removeWidget();

	// Determine environment, reload if necessary
	const environment = presetEnvironment || getEnvironment() || "production";
	if (environment !== "production" && !presetEnvironment) {
		void loadUpsyBranch(environment, customerId);
		return;
	}

	// Set up the elements
	const body: HTMLElement = document.body;
	const target: HTMLElement = document.createElement("div");
	const shadowDOM: ShadowRoot = target.attachShadow({ mode: "open" });
	target.className = "upsy-plugin";
	//Upsy widget should not be hidden by merchant store specific code (css, js)
	target.hidden = false;
	target.setAttribute("style", "display: block; opacity: 1; visibility: visible;");

	target.setAttribute("plugin-name", "upsy plugin 3.0");
	body.insertAdjacentElement("beforeend", target);

	// Load config
	const { apiURL, staticResourceURL } = getEnvironmentEndpoints(environment);
	const configUrl = getStoreConfigsApiURL(getUrlByVersion(apiURL, "v1"), customerId);
	const cssUrl = getStaticCSSURL(staticResourceURL);
	const configPromise = loadConfig(configUrl);

	// Load up styles
	const stylesPromise = new Promise<void>(resolve => {
		const linkElement = document.createElement("link");
		linkElement.setAttribute("rel", "stylesheet");
		linkElement.setAttribute("href", cssUrl);
		shadowDOM.appendChild(linkElement);
		linkElement.onload = () => {
			resolve();
		};
	});

	try {
		// Set upsy session id if already not exists
		if (!getUpsySessionId()) {
			setUpsySessionId(customerId);
		}
		// Wait for both styles and config to load
		const [config] = await Promise.all([configPromise, stylesPromise]);

		const upsyConfig = config.data.config.upsyConfig;
		//Add ability to inject custom css to customize website styling through fe configs if available
		upsyConfig.customCssForSite && injectCustomCss(upsyConfig.customCssForSite);

		upsyConfig.tenantId = customerId;

		storeConfig.set({ ...upsyConfig });
		//Add pre-baked SDK functions to the upsy_sdk object
		window.upsy_sdk = {
			...window.upsy_sdk,
			loadUpsyBranch: (upsyBranch: string) =>
				loadUpsyBranch(upsyBranch, customerId),
			getTenantId: () => customerId,
			getOrder: getOrder,
			getPageType: () => getPageType(upsyConfig),
			isThankYouPage: () => getPageType(upsyConfig) === pageTypeEnum.THANKYOU,
			getUpsySession: () => getUpsySessionId(),
			getCookie: cookies.getCookie.bind(cookies),
			setCookie: cookies.setCookie.bind(cookies)
		};
		isSdkLoaded.set(true);

		//Add widget locale base on document(page) lang only if supppored by upsy widget, otherwise fallback to cofiguration language value
		updateLocaleLanguage(upsyConfig.language);

		//Hide Upsy widget on selective url (base on flows config)
		if (isUpsyHideOnThisUrl(upsyConfig.flows)) {
			return;
		}
		activePage.set({ pageType: getPageType(upsyConfig) });
		/**
		 * `loadUpsy` is a function that creates a new Upsy App component and adds pre-baked SDK functions to the
		 * upsy_sdk object that is added to the global window object. It also posts an `init` event to Upsy Service Layer
		 * and sets a listener to track if the page should post an Upsy `purchase` event.
		 */
		const loadUpsy = () => {
			//Ab test related stuff
			if (checkIsAbTestEnabled(upsyConfig) && configureAbTest(upsyConfig, target)) {
				sendAbTestEvents(getUrlByVersion(apiURL, "v1"));
			}

			new App({
				target: shadowDOM,
				props: {
					targetElement: target,
					environment: environment
				}
			});
			// Post Upsy init event. This will trigger on every page refresh or every new page load
			void postInitEvent(upsyConfig, environment);

			/* This is to post purchase event to Upsy server, if flow trigger isPurchase is true and  noPurchaseEvents is not true explicitly */
			if (getPageType(upsyConfig) === pageTypeEnum.THANKYOU) {
				if (upsyConfig.noPurchaseEvents !== true) {
					const order = getOrder();
					if (
						order &&
						order.length &&
						!cookies.getCookie(UPSY_PURCHASE_COMPLETED_COOKIE_KEY)
					) {
						void postPurchaseEvent(upsyConfig, environment, order);
					} else {
						void postPurchaseEvent(upsyConfig, environment);
					}
				} else {
					/* Empty upsy local storage cart products when noPurchaseEvents is true.
					 ** Becasue usually when upsy send purchase event, upsy clear its cart items from local storage.
					 ** And in this case upsy plugin (woo-com) might be envloved with sending purchase event,
					 ** so local storage cart products need to empty manually.
					 */
					setStorage(STORAGE_UPSY_CART_KEY, []);
				}
			}
		};

		/** This is to check if the Upsy test mode is enabled or not. If test mode is enabled, then it will check if there
		 * exists an Upsy test string ("upsytesti") in the current url, and if it does,
		 * it will load Upsy and set the test mode cookie. Otherwise, it will check if the test mode cookie is set or not.
		 * If test mode cookie is not set, Upsy won't load, but if the test mode cookie is set it will simply load the widget.
		 * If the test mode is not enabled at all, then it will load the widget normally.
		 */
		if (Number(upsyConfig.testMode) === 1) {
			// If testMode value is `true / 1 / "1"` only then it will enter inside this if block.
			// string "0" -> boolean return true thus its good to wrap with `Number` function.(backward compatibility)

			const upsyTestCookie = cookies.getCookie(UPSY_TEST_MODE_COOKIE_KEY);
			if (window.location.href.indexOf(UPSY_TEST_MODE_URL_KEY) > -1) {
				const halfDay = 0.5; // 1/2 == 0.5 day
				cookies.setCookie(UPSY_TEST_MODE_COOKIE_KEY, "1", halfDay);
				loadUpsy();
			} else if (upsyTestCookie && upsyTestCookie == "1") {
				loadUpsy();
			}
		} else {
			loadUpsy();
		}
	} catch (error) {
		console.error(error);
	}
};

export const init = (customerId: string, presetEnvironment = "") => {
	/*If the document is currently in loading state, thats mean page is not ready to interact with upsy so we add listener on document readystatechange
	 * and when the readState value is interactive thats means the page is now ready to interact with upsy and we then call the widget initialize function.
	 */

	customerId = customerId.trim();
	presetEnvironment = presetEnvironment.trim();

	if (document.readyState == "loading") {
		document.addEventListener("readystatechange", () => {
			if (document.readyState == "interactive") {
				void initialize(customerId, presetEnvironment);
			}
		});
	} else {
		// If the document is currently not in loading state, thats means the page is now ready to interact with upsy and then immediately call the widget initialize function.
		void initialize(customerId, presetEnvironment);
	}
};

//This function will inject style element with custom css on the specific store body element
const injectCustomCss = (customCss?: string) => {
	if (!customCss) return;
	const styleElement = document.createElement("style");
	styleElement.textContent = customCss;
	styleElement.className = "upsy-widget-injected-css";
	document.body.insertAdjacentElement("beforeend", styleElement);
};
