export default {
	greeting_lg: "Was haben Sie auf dem Herzen",
	new: "Neu",
	sale: "Verkauf",
	chat_btn_text: "Klicken Sie hier, um mit uns zu chatten",
	show_less: "zeige weniger",
	see_all: "zeige alles",
	submit: "einreichen",
	feedback_textInBubble: "Konnte ich Ihnen beim Kauf helfen?",
	feedback_positiveFeedback: "Vielen Dank! Was hat dir gefallen?",
	feedback_negativeFeedback: "Vielen Dank! Was können wir verbessern?",
	loading: "Wird geladen",
	no_product_found: "Es wurden leider keine Produkte gefunden",
	categories: "Kategorien",
	new_arrivals: "Neuankömmlinge",
	daily_deals: "Tägliche Angebote",
	similar_products: "Ähnliche Produkte",
	faqs: "FAQs",
	popular_categories: "Beliebte Kategorien",
	newest_products: "Neueste Produkte",
	best_deals: "Beste Angebote",
	others_also_purchased: "Andere auch gekauft",
	have_you_seen_these: "Hast du diese gesehen",
	any_questions: "Irgendwelche Fragen",
	frequently_asked_questions: "Häufig gestellte Fragen",
	thank_you: "Danke dir",
	good_morning: "Guten Morgen",
	good_noon: "Guten Tag",
	good_afternoon: "Guten Nachmittag",
	good_evening: "Guten Abend",
	good_night: "Gute Nacht",
	no_categories_found: "Es tut uns leid! Keine Kategorien gefunden",
	browse_new_products: "Durchsuchen Sie neue Produkte",
	do_u_need_help: "Brauchst du Hilfe",
	sidebar_cta_text_for_daily_deals: "Siehe Angebote",
	sidebar_cta_text_for_up_sell: "Siehe Optionen",
	sidebar_cta_text_for_cross_sell: "Andere kauften",
	sidebar_cta_text_for_new_arrival: "Neuzugänge",
	sidebar_cta_text_for_front_page: "Schnelles Shoppen"
};
