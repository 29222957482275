export enum timePeriodEnum {
	MORNING = "MORNING",
	NOON = "NOON",
	AFTERNOON = "AFTERNOON",
	EVENING = "EVENING",
	NIGHT = "NIGHT"
}

type timePeriodType = `${timePeriodEnum}`;
export default timePeriodType;
