export default {
	greeting_lg: "Mis sul mõttes on",
	new: "Uus",
	sale: "Soodustus",
	chat_btn_text: "Vajuta siia, et meiega vestelda",
	show_less: "Näita vähem",
	see_all: "Näita kõiki",
	submit: "Saada",
	feedback_textInBubble: "Kas sain sind aidata ostu sooritamisel?",
	feedback_positiveFeedback: "Aitäh! Mis sulle meeldis?",
	feedback_negativeFeedback: "Aitäh! Mida saaksime parandada?",
	loading: "laadimine",
	no_product_found: "Vabandame, tooteid ei leitud",
	categories: "Kategooriad",
	new_arrivals: "Uued tooted",
	daily_deals: "Päevapakkumised",
	similar_products: "Sarnased tooted",
	faqs: "KKK",
	popular_categories: "Populaarsed kategooriad",
	newest_products: "Uusimad tooted",
	best_deals: "Parimad pakkumised",
	others_also_purchased: "Teised ostsid ka",
	have_you_seen_these: "Kas oled neid näinud?",
	any_questions: "Kas küsimusi?",
	frequently_asked_questions: "Korduma kippuvad küsimused",
	thank_you: "Aitäh",
	good_morning: "Tere hommikust",
	good_noon: "Tere keskpäeva",
	good_afternoon: "Tere pärastlõunat",
	good_evening: "Tere õhtust",
	good_night: "Head ööd",
	no_categories_found: "Vabandame! Kategooriaid ei leitud",
	browse_new_products: "Sirvi uusi tooteid",
	do_u_need_help: "Kas vajad abi",
	sidebar_cta_text_for_daily_deals: "Vaata pakkumisi",
	sidebar_cta_text_for_up_sell: "Vaata valikuid",
	sidebar_cta_text_for_cross_sell: "Teised ostsid",
	sidebar_cta_text_for_new_arrival: "Uued tooted",
	sidebar_cta_text_for_front_page: "Kiire ost"
};
