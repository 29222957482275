import { SESSION_COOKIE_KEY } from "../constants";
import { cookies } from "./cookieManager";

export function upsyGenerateId(length: number) {
	let s = "";
	do {
		s += Math.random().toString(36).substring(2);
	} while (s.length < length);
	s = s.substring(0, length);
	return s;
}

export function generateUpsySession(customerId: string, length = 16) {
	return customerId + "-sid-" + upsyGenerateId(length);
}

export function getUpsySessionId() {
	const sessionId = cookies.getCookie(SESSION_COOKIE_KEY);
	if (!sessionId) return "";
	// 2 hours expiries time in days, (1/24)*2 = 0.08333 days
	const twoHoursInDays = 0.08333;
	cookies.setCookie(SESSION_COOKIE_KEY, sessionId, twoHoursInDays);
	return sessionId;
}

export function setUpsySessionId(customerId: string): string {
	const sessionId = generateUpsySession(customerId);
	// 2 hours expiries time in days, (1/24)*2 = 0.08333 days
	const twoHoursInDays = 0.08333;
	cookies.setCookie(SESSION_COOKIE_KEY, sessionId, twoHoursInDays);
	return sessionId;
}
