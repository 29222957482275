import {
	FallBackProductResponse,
	NewArrivalProductResponse,
	DailyDealsProductResponse
} from "../types/productResponse";
import { IProductResponse } from "../types/productResponse";
import { IUpsyContext, UpsyContextEnum } from "../types/context";
import { getEnvironmentEndpoints } from "./getEnvironmentEndpoints";
import {
	getUrlByVersion,
	getCrossSellURL,
	getDailyDealsURL,
	getFallbackURL,
	getNewArrivalURL,
	getRelatedURL,
	getUpSellURL
} from "./getUpsyUrls";
import { getProductId } from "./product";
import { IProduct } from "../types/product";
import { getCategoryId, getFormattedCategory } from "./category";
import { IConfigObject } from "../types/sdk";

const NINETY_DAYS_IN_MS = 7776000000;

const isEmpty = (data?: unknown[]): boolean => !Array.isArray(data) || data.length === 0;

type ProductHandlerFn = () => Promise<IProduct[]>;

// Factory function to return the correct product handler
function createProductHandlerFactory(config: IConfigObject, apiURL: string) {
	const {
		apiVersion = "v1",
		productOptions: { customFilter } = {},
		tenantId: storeId
	} = config;

	// Define handlers
	const productHandlers: Partial<Record<UpsyContextEnum, ProductHandlerFn>> = {
		[UpsyContextEnum.UP_SELL]: async () =>
			getUpsellProducts(storeId, apiURL, apiVersion, customFilter),
		[UpsyContextEnum.CROSS_SELL]: async () =>
			getCrossSellProducts(storeId, apiURL, "v1", customFilter),
		[UpsyContextEnum.NEW_ARRIVAL]: async () =>
			getNewArrivalProducts(storeId, apiURL, "v1", {
				timeLimit: config.newArrivalTimeLimit,
				sortingOrder: config.newArrivalSortingOrder
			}),
		[UpsyContextEnum.DAILY_DEALS]: async () =>
			getDailyDealsProducts(storeId, apiURL, "v1", customFilter)
	};

	// Function to get the handler based on the context
	return function (upsyContext: UpsyContextEnum): ProductHandlerFn {
		const handler = productHandlers[upsyContext];
		if (!handler) {
			throw new Error(`No product handler found for context: ${upsyContext}.`);
		}
		return handler;
	};
}

export async function getUpsyContexualProducts(
	config: IConfigObject,
	environment: string,
	upsyContext: IUpsyContext
): Promise<IProduct[]> {
	const { apiURL } = getEnvironmentEndpoints(environment);
	const getProductHandler = createProductHandlerFactory(config, apiURL);
	// Call the handler and return the products
	try {
		return await getProductHandler(upsyContext as UpsyContextEnum)();
	} catch (e) {
		console.error(e);
		return [];
	}
}

function createUpsellHandlerFactory(
	storeId: string,
	apiUrl: string,
	customFilter?: string
) {
	const upsellHandlers: Record<string, () => Promise<Response>> = {
		v1: async () => getUpsellV1(storeId, apiUrl, customFilter),
		v2: async () => getUpsellV2(storeId, apiUrl, customFilter)
	};
	return function (apiVersion: string) {
		const handler = upsellHandlers[apiVersion];
		if (!handler)
			throw new Error(`No upsell handler found for api version: ${apiVersion}`);
		return handler;
	};
}
async function getUpsellProducts(
	storeId: string,
	apiUrl: string,
	apiVersion: string,
	customFilter?: string
) {
	try {
		const getUpsellHandler = createUpsellHandlerFactory(
			storeId,
			apiUrl,
			customFilter
		);
		const response = await getUpsellHandler(apiVersion)();
		const data = (await response.json()) as unknown as IProductResponse;
		if (!isEmpty(data?.results)) {
			return sortByPrice(data.results);
		}
	} catch (e) {
		console.warn(e);
	}
	// Fetch fallback products only when upsell failed or empty upsell
	return await fetchFallBackProducts(storeId, apiUrl, "v1", customFilter);
}
async function getUpsellV1(storeId: string, apiUrl: string, customFilter?: string) {
	const productId = getProductId();
	if (!productId) throw new Error("productId notFound");
	return await fetch(
		getUpSellURL(getUrlByVersion(apiUrl, "v1"), storeId, productId, customFilter)
	);
}

async function getUpsellV2(storeId: string, apiUrl: string, customFilter?: string) {
	const productId = getProductId();
	if (!productId) throw new Error("productId notFound");

	const productUrl = window.location.href.split("?")[0];

	const headers = new Headers({
		"Content-Type": "text/plain"
	});

	const body = {
		productUrl
	};

	const options: RequestInit = {
		method: "POST",
		body: JSON.stringify(body),
		headers
	};
	return await fetch(
		getUpSellURL(getUrlByVersion(apiUrl, "v2"), storeId, productId, customFilter),
		options
	);
}

async function getCrossSellProducts(
	storeId: string,
	apiUrl: string,
	apiVersion: string,
	customFilter?: string
) {
	const productId = getProductId();
	if (!productId) {
		return [];
	}
	const response = await fetch(
		getCrossSellURL(
			getUrlByVersion(apiUrl, apiVersion),
			storeId,
			productId,
			customFilter
		)
	);
	const data = (await response.json()) as unknown as IProductResponse;
	return !isEmpty(data?.results) ? sortByPrice(data.results) : [];
}

export async function fetchRelatedProducts(
	storeId: string,
	apiUrl: string,
	apiVersion: string
) {
	const productId = getProductId();
	if (!productId) {
		return [];
	}
	const relatedUrl = getRelatedURL(
		getUrlByVersion(apiUrl, apiVersion),
		storeId,
		productId
	);
	const response = await fetch(relatedUrl);
	const data = (await response.json()) as unknown as IProductResponse;
	return !isEmpty(data?.results) ? sortByPrice(data.results) : [];
}

export async function fetchFallBackProducts(
	storeId: string,
	apiUrl: string,
	apiVersion: string,
	customFilter?: string
): Promise<IProduct[]> {
	const productId = getProductId();
	if (!productId) {
		return [];
	}
	const fallbackUrl = getFallbackURL(
		getUrlByVersion(apiUrl, apiVersion),
		storeId,
		productId,
		customFilter
	);
	const response = await fetch(fallbackUrl);
	const data = (await response.json()) as unknown as FallBackProductResponse;
	return !isEmpty(data?.results?.[0]?.hits)
		? sortByPrice(data?.results?.[0]?.hits)
		: [];
}

export interface NewArrivalApiOptions {
	page?: number;
	timeLimit?: number;
	hitsPerPage?: number;
	sortingOrder?: string;
}

export async function getNewArrivalProducts(
	storeId: string,
	apiUrl: string,
	apiVersion: string,
	options: NewArrivalApiOptions = {}
): Promise<IProduct[]> {
	let url = getNewArrivalURL(getUrlByVersion(apiUrl, apiVersion), storeId);
	const categoryName = getFormattedCategory(getCategoryId());

	const { page, hitsPerPage, timeLimit, sortingOrder } = {
		page: options.page || 0,
		hitsPerPage: options.hitsPerPage || 30,
		timeLimit: options.timeLimit || NINETY_DAYS_IN_MS,
		sortingOrder: (options.sortingOrder || "").trim()
	};

	url += `/?timeLimit=${timeLimit}&page=${page}&hitsPerPage=${hitsPerPage}`;
	if (categoryName) {
		url += `&categories=${encodeURIComponent(categoryName)}`;
	}

	if (sortingOrder) url += `&sortingOrder=${encodeURIComponent(sortingOrder)}`;

	const response = await fetch(url);
	const data = (await response.json()) as unknown as NewArrivalProductResponse;
	return !isEmpty(data?.hits) ? data.hits : [];
}

export async function getDailyDealsProducts(
	storeId: string,
	apiUrl: string,
	apiVersion: string,
	customFilter?: string,
	page = 0,
	hitsPerPage = 30
): Promise<IProduct[]> {
	const categoryName = getFormattedCategory(getCategoryId()) as string | undefined;
	const url = getDailyDealsURL(
		getUrlByVersion(apiUrl, apiVersion),
		storeId,
		categoryName,
		customFilter,
		page,
		hitsPerPage
	);
	const response = await fetch(url);
	const data = (await response.json()) as unknown as DailyDealsProductResponse;
	return !isEmpty(data?.hits) ? data.hits : [];
}

const getMinPrice = (product: IProduct) => {
	if (!product.salePrice) return parseFloat(`${product.price}`);
	return Math.min(parseFloat(`${product.salePrice}`), parseFloat(`${product.price}`));
};
const sortByPrice = (products: IProduct[], descendingOrder = false) => {
	return products.sort((a, b) =>
		descendingOrder
			? getMinPrice(b) - getMinPrice(a)
			: getMinPrice(a) - getMinPrice(b)
	);
};
