<script lang="ts">
	import { storeConfig } from "../../stores/storeConfig";
	$: bgColor = $storeConfig.buttonColor || "#189DEB";
	$: accentColorLight = $storeConfig.accentColorLight || "#ffffff";
	export let height = 24;
	export let width = 24;
</script>

<span
	class=" u-h-6 u-w-6 u-overflow-hidden mobile-and-table-device:u-bg-accent mobile-and-table-device:u-text-white mobile-and-table-device:u-rounded-default u-cursor-pointer mobile-and-table-device u-absolute u-bottom-[169px] sm:u-top-[16px] u-right-0"
	on:click>
	<svg
		{width}
		{height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
			fill={`${bgColor}`} />
		<path
			d="M11.2474 16.867L6.8474 12.467C6.78073 12.4004 6.7334 12.3282 6.7054 12.2504C6.67784 12.1726 6.66406 12.0893 6.66406 12.0004C6.66406 11.9115 6.67784 11.8282 6.7054 11.7504C6.7334 11.6726 6.78073 11.6004 6.8474 11.5337L11.2474 7.13371C11.3696 7.01149 11.5223 6.94749 11.7054 6.94171C11.889 6.93638 12.0474 7.00038 12.1807 7.13371C12.3141 7.25594 12.3836 7.4086 12.3894 7.59171C12.3947 7.77527 12.3307 7.93371 12.1974 8.06705L8.93073 11.3337H16.3807C16.5696 11.3337 16.7281 11.3975 16.8561 11.525C16.9836 11.653 17.0474 11.8115 17.0474 12.0004C17.0474 12.1893 16.9836 12.3475 16.8561 12.475C16.7281 12.603 16.5696 12.667 16.3807 12.667H8.93073L12.1974 15.9337C12.3196 16.0559 12.3836 16.2115 12.3894 16.4004C12.3947 16.5893 12.3307 16.7448 12.1974 16.867C12.0752 17.0004 11.9196 17.067 11.7307 17.067C11.5418 17.067 11.3807 17.0004 11.2474 16.867Z"
			fill={`${accentColorLight}`} />
	</svg>
</span>
