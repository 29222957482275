<script lang="ts">
	import { t } from "../../i18n/i18n";
	import { storeConfig } from "../../stores/storeConfig";
	import IPositionProps from "../../types/positionProps";
	import { pageTypeEnum } from "../../utils/pageType";
	import { activePage } from "../../stores/activePage";
	import getPositionPropsToCss from "../../utils/positionPropsToCss";
	import ChatNavigateButton from "./ChatNavigateButton.svelte";
	const chatBtnEnablePages = new Set([pageTypeEnum.FRONT]);

	$: widgetToLeft = $storeConfig.widgetToLeft;
	$: avatar = $storeConfig.avatar;
	$: bgColor = $storeConfig.buttonColor || "#189DEB";

	export let positionProps: IPositionProps;
	export let welcomeText = $t("greeting_lg") + "?";
	export let showLogoImg = true;
	$: dynamicStyles = `${getPositionPropsToCss(positionProps)}`;
	$: shouldShowChatBtn = chatBtnEnablePages.has($activePage?.pageType as pageTypeEnum);
</script>

<div
	on:click
	style={dynamicStyles}
	class="u-fixed u-right-0 u-bottom-0 u-max-w-[84%] u-w-[286px] u-h-[72px] u-drop-shadow-welcome u-cursor-pointer">
	<svg
		class={`u-absolute u-top-0 u-left-0 u-w-full u-h-full -u-z-[1] ${
			widgetToLeft ? "u-rotate-y-180" : ""
		}`}
		viewBox="0 0 337 85"
		height="100%"
		width="100%"
		preserveAspectRatio="none"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M53.063 14.5364C33.3958 14.3142 22.5644 13.3814 5 5C5 5 10.7146 16.6239 13.0105 24.6026C16.9605 38.3297 15.3878 51.8899 18.2006 65.9272C22.0223 85 42.7327 85 42.7327 85H300.387C349.952 85 348.45 13.4768 300.387 14.5364H53.063Z"
			fill="white" />
		<path
			d="M48 9C28.3586 8.79025 17.5414 7.90995 0 0C0 0 5.70711 10.9701 8 18.5C11.9448 31.4549 10.3742 44.2523 13.1832 57.5C17 75.5 37.6832 75.5 37.6832 75.5H295C344.5 75.5 343 8 295 9H48Z"
			fill={bgColor} />
	</svg>
	<div
		class={`u-flex u-justify-between u-items-center u-absolute u-top-0 u-left-0 u-w-full u-h-full ${
			widgetToLeft ? "u-flex-row-reverse" : "u-flex-row"
		} `}>
		<p
			class={`u-font-inter u-basis-[74%] u-mr-1 u-line-clamp-2 u-leading-4 u-text-[13.6px] u-font-medium u-text-white ${
				widgetToLeft ? " u-mr-8 " : "u-ml-[9.5%]"
			}`}>
			{welcomeText}
		</p>
		{#if showLogoImg}
			<div
				style={avatar
					? `background-image:url('${avatar
							.replace(/'/g, "%27")
							.replace(/"/g, "%22")}')`
					: ``}
				class={`u-h-[38.25px] u-w-[38.25px] u-bg-accent-light u-mr-[4.3%] u-bg-cover u-bg-center ${
					widgetToLeft
						? "u-rounded-bl-[50%] u-rounded-tl-[50%] u-ml-3"
						: "u-rounded-br-[50%] u-rounded-tr-[50%]"
				}`} />
		{/if}
	</div>
	{#if shouldShowChatBtn}
		<ChatNavigateButton on:chatNavigate {bgColor} isCTABarLift />
	{/if}
</div>
