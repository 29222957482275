<script lang="ts">
	import { getContext } from "svelte";
	import { storeConfig } from "../../stores/storeConfig";
	import { ICategory } from "../../types/category";
	import { GlobalContextKeyEnum } from "../../types/context";
	import { IConfigObject } from "../../types/sdk";
	import variantType, { variantEnum } from "../../types/variant";
	import { postCategoryClickEvent } from "../../utils/event";
	import { locale } from "./../../i18n/i18n";
	export let previewMode = false;
	export let variant: variantType = variantEnum.MINIFIED;
	export let category: ICategory;
	const { categoryAvatarURL, order } = category;
	// category localize(categoryName, categoryURL) section
	$: categoryName =
		category.locale?.[$locale]?.categoryName || category.categoryName || "";
	$: categoryURL =
		category.locale?.[$locale]?.categoryURL || category.categoryURL || "";
	let upsyConfig: IConfigObject;
	$: upsyConfig = $storeConfig;
	const environment = getContext<string>(GlobalContextKeyEnum.ENVIRONMENT);
	function productClickHandler() {
		try {
			!previewMode &&
				void postCategoryClickEvent(
					upsyConfig,
					environment,
					categoryName as string,
					order
				);
		} catch (error) {
			console.log(error);
		}
	}

	let classZ = "";
	export { classZ as class };
</script>

<a
	href={categoryURL}
	on:click={productClickHandler}
	class={`${
		variant === variantEnum.MINIFIED
			? "u-w-[100px] u-h-[160px] sm:u-w-[105px] sm:u-h-[168px]"
			: "u-w-[102px] u-h-[189.55px]"
	} u-bg-card-gray u-block u-no-underline u-cursor-pointer u-shadow-upsy u-rounded-[10px] u-bg-white  u-grow-0 u-shrink-0 u-overflow-hidden ${classZ}`}>
	<div
		class={`${
			variant === variantEnum.MINIFIED ? "u-h-[60%]" : "u-h-[60%]"
		} u-flex u-justify-center u-items-center u-overflow-hidden u-bg-white`}>
		<div
			style={categoryAvatarURL
				? `background-image:url('${categoryAvatarURL
						.replace(/'/g, "%27")
						.replace(/"/g, "%22")}')`
				: ``}
			class=" u-w-[80px] u-h-[80px] u-overflow-hidden u-bg-center  u-bg-contain u-bg-origin-border u-bg-no-repeat u-bg-white" />
	</div>
	<div class={`u-hide-scroll u-w-full u-bg-card-gray u-flex u-flex-col`}>
		<p
			class={`${
				variant === variantEnum.MINIFIED
					? "u-text-xs u-leading-4 u-my-2 sm:u-my-3"
					: "u-text-[14px] u-leading-5 u-my-3"
			} u-text-black-secondary u-line-clamp-3 u-mx-2 u-font-inter u-font-semibold u-tracking-normal`}>
			{categoryName}
		</p>
	</div>
</a>
