import { derived } from "svelte/store";
import { locale, t } from "../i18n/i18n";
import {
	activeWidgetScreenEnum,
	activeWidgetScreenType
} from "../types/activeWidgetScreen";
import { IUpsyContext, UpsyContextEnum } from "../types/context";
import { IGreetings } from "../types/sdk";
import timePeriodType, { timePeriodEnum } from "../types/timePeriod";
import { pageTypeEnum } from "./pageType";
import { get } from "svelte/store";
import { locale as localeStore } from "../i18n/i18n";
export function getTimePeriod(): timePeriodType {
	let timePeriod: timePeriodType = timePeriodEnum.NIGHT;
	const date: Date = new Date();
	const hour: number = date.getHours();

	if (hour >= 6 && hour < 12) {
		timePeriod = timePeriodEnum.MORNING;
	} else if (hour === 12) {
		timePeriod = timePeriodEnum.NOON;
	} else if (hour >= 12 && hour <= 17) {
		timePeriod = timePeriodEnum.AFTERNOON;
	} else if (hour > 17 && hour < 20) {
		timePeriod = timePeriodEnum.EVENING;
	} else {
		timePeriod = timePeriodEnum.NIGHT;
	}
	return timePeriod;
}

export const getTextInBubble = derived(t, $t => {
	return (activeWidgetScreen: activeWidgetScreenType, greetings: IGreetings = {}) => {
		if (!activeWidgetScreen) {
			return $t("greeting_lg") + "?";
		}
		const locale = get(localeStore);
		//Localize generics bubble text
		const textInBubble =
			greetings.homePage?.locale?.[locale]?.textInBubble ||
			greetings.homePage?.textInBubble ||
			$t("greeting_lg") + "?";
		//Localize faq bubble text
		const faqTextInBubble =
			greetings.faq?.locale?.[locale]?.textInBubble ||
			greetings.faq?.textInBubble ||
			$t("any_questions") + "?";

		const mapActiveScreenToTextInBubble = {
			[activeWidgetScreenEnum.THANKYOU]: $t("thank_you") + "!",
			[activeWidgetScreenEnum.ANYQUESTION]: faqTextInBubble,
			[activeWidgetScreenEnum.FEEDBACK]: $t("feedback_textInBubble"),
			[activeWidgetScreenEnum.GREETINGLG]: textInBubble,
			[activeWidgetScreenEnum.GREETINGSM]: textInBubble,
			[activeWidgetScreenEnum.WIDGETEXPANDED]: textInBubble,
			[activeWidgetScreenEnum.WIDGETMINIFIED]: textInBubble
		};
		return mapActiveScreenToTextInBubble[activeWidgetScreen];
	};
});

export const getUpperTextByTimePeriod = derived<typeof t, () => string>(t, $t => {
	return () => {
		const mapTimePeriodToUpperText = {
			[timePeriodEnum.MORNING]: $t("good_morning"),
			[timePeriodEnum.NOON]: $t("good_noon"),
			[timePeriodEnum.AFTERNOON]: $t("good_afternoon"),
			[timePeriodEnum.EVENING]: $t("good_evening"),
			[timePeriodEnum.NIGHT]: $t("good_night")
		};
		const timePeriod = getTimePeriod();
		return mapTimePeriodToUpperText[timePeriod];
	};
});
export const getUpperText = derived(locale, $locale => {
	return (context: IUpsyContext, greetings: IGreetings = {}): string | void | null => {
		if (!context) {
			return;
		}
		const mapContextToUpperText = {
			[UpsyContextEnum.CATEGORY]:
				greetings.category?.locale?.[$locale]?.upperText ||
				greetings.category?.upperText,
			[UpsyContextEnum.CROSS_SELL]:
				greetings.crossSell?.locale?.[$locale]?.upperText ||
				greetings.crossSell?.upperText,
			[UpsyContextEnum.DAILY_DEALS]:
				greetings.dailyDeal?.locale?.[$locale]?.upperText ||
				greetings.dailyDeal?.upperText,
			[UpsyContextEnum.FAQ]:
				greetings.faq?.locale?.[$locale]?.upperText || greetings.faq?.upperText,
			[UpsyContextEnum.NEW_ARRIVAL]:
				greetings.newArrival?.locale?.[$locale]?.upperText ||
				greetings.newArrival?.upperText,
			[UpsyContextEnum.RELATED_SELL]: null,
			[UpsyContextEnum.UP_SELL]:
				greetings.upSell?.locale?.[$locale]?.upperText ||
				greetings.upSell?.upperText
		};
		return mapContextToUpperText[context];
	};
});

export const getSubHeading = derived(t, $t => {
	return (upsyContext: IUpsyContext, greetings: IGreetings = {}) => {
		if (!upsyContext) {
			return "";
		}
		const mapContextToSubHeading = {
			[UpsyContextEnum.CATEGORY]: $t("popular_categories"),
			[UpsyContextEnum.CROSS_SELL]: $t("others_also_purchased") + "..",
			[UpsyContextEnum.DAILY_DEALS]: $t("best_deals"),
			[UpsyContextEnum.FAQ]: $t("frequently_asked_questions"),
			[UpsyContextEnum.NEW_ARRIVAL]: $t("newest_products"),
			[UpsyContextEnum.RELATED_SELL]: $t("similar_products"),
			[UpsyContextEnum.UP_SELL]: $t("similar_products")
		};
		return mapContextToSubHeading[upsyContext];
	};
});

export const getSideBarCtaText = derived(t, $t => {
	return (upsyContext: IUpsyContext, pageType?: string) => {
		if (pageTypeEnum.FRONT == pageType) {
			return $t("sidebar_cta_text_for_front_page");
		}
		switch (upsyContext) {
			case UpsyContextEnum.UP_SELL:
				return $t("sidebar_cta_text_for_up_sell");
			case UpsyContextEnum.CROSS_SELL:
				return $t("sidebar_cta_text_for_cross_sell");
			case UpsyContextEnum.NEW_ARRIVAL:
				return $t("sidebar_cta_text_for_new_arrival");
			case UpsyContextEnum.DAILY_DEALS:
				return $t("sidebar_cta_text_for_daily_deals");
			default:
				return $t("popular_categories");
		}
	};
});

export function getIconByTimePeriod() {
	let icon = "";
	const timePeriod = getTimePeriod();
	if (timePeriodEnum.AFTERNOON === timePeriod) {
		icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.88889 1.11111C8.88889 1.11111 8.88889 0 10 0C11.1111 0 11.1111 1.11111 11.1111 1.11111V2.22222C11.1111 2.22222 11.1111 3.33333 10 3.33333C8.88889 3.33333 8.88889 2.22222 8.88889 2.22222V1.11111ZM18.8889 8.88889C18.8889 8.88889 20 8.88889 20 10C20 11.1111 18.8889 11.1111 18.8889 11.1111H17.7778C17.7778 11.1111 16.6667 11.1111 16.6667 10C16.6667 8.88889 17.7778 8.88889 17.7778 8.88889H18.8889ZM2.22222 8.88889C2.22222 8.88889 3.33333 8.88889 3.33333 10C3.33333 11.1111 2.22222 11.1111 2.22222 11.1111H1.11111C1.11111 11.1111 0 11.1111 0 10C0 8.88889 1.11111 8.88889 1.11111 8.88889H2.22222ZM5.06722 4.05167C5.06722 4.05167 5.85278 4.83722 5.06722 5.62278C4.28167 6.40833 3.49611 5.62278 3.49611 5.62278L2.71 4.83778C2.71 4.83778 1.92444 4.05222 2.71 3.26611C3.49611 2.48056 4.28167 3.26611 4.28167 3.26611L5.06722 4.05167ZM16.5044 5.62278C16.5044 5.62278 15.7189 6.40833 14.9333 5.62278C14.1478 4.83722 14.9333 4.05167 14.9333 4.05167L15.7189 3.26611C15.7189 3.26611 16.5044 2.48056 17.29 3.26611C18.0756 4.05167 17.29 4.83722 17.29 4.83722L16.5044 5.62278ZM4.83778 17.2894C4.83778 17.2894 4.05222 18.075 3.26667 17.2894C2.48111 16.5039 3.26667 15.7183 3.26667 15.7183L4.05222 14.9328C4.05222 14.9328 4.83778 14.1472 5.62333 14.9328C6.40889 15.7183 5.62333 16.5039 5.62333 16.5039L4.83778 17.2894Z" fill="#FFAC33"/>
        <path d="M10 15.5556C13.0683 15.5556 15.5556 13.0683 15.5556 10C15.5556 6.93176 13.0683 4.44446 10 4.44446C6.93176 4.44446 4.44446 6.93176 4.44446 10C4.44446 13.0683 6.93176 15.5556 10 15.5556Z" fill="#FFAC33"/>
        <path d="M16.5428 12.8889C16.1861 12.8889 15.8428 12.9444 15.5189 13.0472C15.1367 11.9205 14.0967 11.1111 12.8706 11.1111C11.555 11.1111 10.4539 12.0433 10.1489 13.2983C9.82335 13.045 9.42168 12.8894 8.98168 12.8894C7.90779 12.8894 7.03724 13.7844 7.03724 14.8894C7.03724 15.1578 7.09057 15.4122 7.1839 15.6466C6.99835 15.5928 6.80668 15.5555 6.60501 15.5555C5.41168 15.5555 4.44446 16.5505 4.44446 17.7778C4.44446 19.005 5.41168 20 6.60501 20H16.5433C18.4522 20 20 18.4089 20 16.4444C20 14.4805 18.4522 12.8889 16.5428 12.8889Z" fill="#E1E8ED"/>
        </svg>`;
	} else if (
		timePeriodEnum.EVENING === timePeriod ||
		timePeriodEnum.NIGHT === timePeriod
	) {
		icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 17.7778C20 19.005 19.005 20 17.7778 20H2.22222C0.995 20 0 19.005 0 17.7778V2.22222C0 0.995 0.995 0 2.22222 0H17.7778C19.005 0 20 0.995 20 2.22222V17.7778Z" fill="#226699"/>
        <path d="M6.11111 4.99999C6.11111 4.99999 10.1611 2.46832 17.7778 2.23943C18.14 2.22832 18.8889 2.22221 18.8889 2.77776C15.5556 7.77776 13.8889 15.5555 13.8889 15.5555L6.11111 4.99999Z" fill="#F4900C"/>
        <path d="M6.66666 8.8889C6.66666 8.8889 13.3333 2.77779 17.7778 2.23945C18.1372 2.19556 18.8889 2.22223 18.8889 2.77779C15 5.00001 12.2222 13.3333 12.2222 13.3333L6.66666 8.8889Z" fill="#FFAC33"/>
        <path d="M8.42014 6.90992C8.87903 7.31381 9.74681 7.55659 10.3485 7.44992L13.0662 6.96881C13.6685 6.86214 13.9624 7.23436 13.7207 7.79547L12.6274 10.3305C12.3851 10.8916 12.4224 11.7916 12.7096 12.3316L14.0068 14.7671C14.2946 15.3071 14.0312 15.7016 13.4229 15.6449L10.6746 15.3877C10.0663 15.331 9.22125 15.6444 8.79736 16.0844L6.88069 18.0716C6.45681 18.5116 6.00014 18.3838 5.86569 17.7871L5.26069 15.0944C5.12681 14.4983 4.56736 13.7916 4.01792 13.5238L1.53625 12.3155C0.986805 12.0483 0.967361 11.5738 1.49292 11.2621L3.86736 9.85436C4.39292 9.5427 4.89236 8.7927 4.97681 8.18714L5.35958 5.45381C5.44403 4.84825 5.88903 4.68325 6.34792 5.08714L8.42014 6.90992Z" fill="#FFD983"/>
        </svg>`;
	} else {
		icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.88889 1.11111C8.88889 1.11111 8.88889 0 10 0C11.1111 0 11.1111 1.11111 11.1111 1.11111V2.22222C11.1111 2.22222 11.1111 3.33333 10 3.33333C8.88889 3.33333 8.88889 2.22222 8.88889 2.22222V1.11111ZM18.8889 8.88889C18.8889 8.88889 20 8.88889 20 10C20 11.1111 18.8889 11.1111 18.8889 11.1111H17.7778C17.7778 11.1111 16.6667 11.1111 16.6667 10C16.6667 8.88889 17.7778 8.88889 17.7778 8.88889H18.8889ZM2.22222 8.88889C2.22222 8.88889 3.33333 8.88889 3.33333 10C3.33333 11.1111 2.22222 11.1111 2.22222 11.1111H1.11111C1.11111 11.1111 0 11.1111 0 10C0 8.88889 1.11111 8.88889 1.11111 8.88889H2.22222ZM5.06722 4.05167C5.06722 4.05167 5.85278 4.83722 5.06722 5.62278C4.28167 6.40833 3.49611 5.62278 3.49611 5.62278L2.71 4.83778C2.71 4.83778 1.92444 4.05222 2.71 3.26611C3.49611 2.48056 4.28167 3.26611 4.28167 3.26611L5.06722 4.05167ZM16.7339 15.7183C16.7339 15.7183 17.5194 16.5039 16.7339 17.2894C15.9483 18.075 15.1628 17.2894 15.1628 17.2894L14.3772 16.5039C14.3772 16.5039 13.5917 15.7183 14.3772 14.9328C15.1628 14.1472 15.9483 14.9328 15.9483 14.9328L16.7339 15.7183ZM16.5044 5.62278C16.5044 5.62278 15.7189 6.40833 14.9333 5.62278C14.1478 4.83722 14.9333 4.05167 14.9333 4.05167L15.7189 3.26611C15.7189 3.26611 16.5044 2.48056 17.29 3.26611C18.0756 4.05167 17.29 4.83722 17.29 4.83722L16.5044 5.62278ZM4.83778 17.2894C4.83778 17.2894 4.05222 18.075 3.26667 17.2894C2.48111 16.5039 3.26667 15.7183 3.26667 15.7183L4.05222 14.9328C4.05222 14.9328 4.83778 14.1472 5.62333 14.9328C6.40889 15.7183 5.62333 16.5039 5.62333 16.5039L4.83778 17.2894ZM8.88889 17.7778C8.88889 17.7778 8.88889 16.6667 10 16.6667C11.1111 16.6667 11.1111 17.7778 11.1111 17.7778V18.8889C11.1111 18.8889 11.1111 20 10 20C8.88889 20 8.88889 18.8889 8.88889 18.8889V17.7778Z" fill="#FFAC33"/>
        <path d="M10 15.5556C13.0683 15.5556 15.5556 13.0683 15.5556 10C15.5556 6.93176 13.0683 4.44446 10 4.44446C6.93176 4.44446 4.44446 6.93176 4.44446 10C4.44446 13.0683 6.93176 15.5556 10 15.5556Z" fill="#FFAC33"/>
        </svg>`;
	}
	return icon;
}
