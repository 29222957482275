/**
 * This function returns a string of conditional CSS classes based on the input parameters.
 * @param {boolean | undefined} widgetToLeft - A boolean value indicating whether the widget is
 * positioned to the left or not. If it is positioned to the left, the function will add a class for a
 * rounded top-right corner. If it is not positioned to the left, the function will add a class for a
 * rounded top-left corner.
 * @param [liftWidget=0px] - A string representing the amount of lift (in pixels) for the widget.
 * @returns a string of CSS classes based on the input parameters `widgetToLeft` and `liftWidget`. The
 * classes returned are for conditional rounding of the top-left and top-right corners of a widget, and
 * optionally the bottom-left and bottom-right corners if `liftWidget` is greater than 0.
 */

export const getConditionalRadiusForExapandedOrMinifiedWidget = (
	widgetToLeft: boolean | undefined,
	liftWidget = "0px"
) => {
	let conditionalClasses = "";
	if (widgetToLeft) {
		conditionalClasses += " u-rounded-tr-[16px]";
	} else {
		conditionalClasses += " u-rounded-tl-[16px]";
	}
	if (parseInt(liftWidget) > 0) {
		if (widgetToLeft) {
			conditionalClasses += " u-rounded-br-[16px]";
		} else {
			conditionalClasses += " u-rounded-bl-[16px]";
		}
	}
	return conditionalClasses;
};

export function enumToObject<T extends { [key: string]: string }>(
	enumObj: T
): { [K in keyof T]: T[K] } {
	return Object.keys(enumObj).reduce((acc, key) => {
		acc[key as keyof T] = enumObj[key as keyof T];
		return acc;
	}, {} as { [K in keyof T]: T[K] });
}
