<script lang="ts">
	import { ISwipePayload, swipeAction } from "../../actions/swipeAction";
	import { activeWidgetScreen } from "../../stores/activeWidgetScreen";
	import { storeConfig } from "../../stores/storeConfig";
	import { upsyContext } from "../../stores/upsyContext";
	import { activeWidgetScreenEnum } from "../../types/activeWidgetScreen";
	import { IConfigObject } from "../../types/sdk";
	import { Dispatch } from "../../types/svelteEventDispatch";
	import variantType, { variantEnum } from "../../types/variant";
	import {
		getIconByTimePeriod,
		getUpperText,
		getSubHeading,
		getUpperTextByTimePeriod
	} from "../../utils/getUpsyText";

	import {
		swipeWidgetExpanded,
		swipeWidgetMinifiedWidget
	} from "../../utils/widgetSwipe";

	export let dispatch: Dispatch;
	function swipeHandler(e: CustomEvent) {
		const detail = e.detail as ISwipePayload;
		const { swipeDirection } = detail;

		if (
			$activeWidgetScreen === activeWidgetScreenEnum.WIDGETEXPANDED &&
			swipeDirection
		) {
			swipeWidgetExpanded(swipeDirection, dispatch);
		} else if (
			$activeWidgetScreen === activeWidgetScreenEnum.WIDGETMINIFIED &&
			swipeDirection
		) {
			swipeWidgetMinifiedWidget(swipeDirection, dispatch);
		}
	}

	let config: IConfigObject;
	$: config = $storeConfig;

	export let variant: variantType = variantEnum.MINIFIED;
	export let heading = "";
	export let subHeading = "";
	let upperText: string | void | null;
	$: upperText = $getUpperText($upsyContext, config.greetings);
</script>

<div
	use:swipeAction
	on:swipe={swipeHandler}
	class="u-basis-[70%] u-word-wrap-break-word u-text-left">
	<p
		class={`${
			variant == variantEnum.MINIFIED
				? "u-hidden sm:u-inline-block"
				: "u-inline-block"
		}  u-relative`}>
		<span
			class={` u-text-black-full u-font-bold u-line-clamp-1 ${
				variant === variantEnum.MINIFIED
					? "u-text-[16px] u-leading-4"
					: " u-text-[17px] u-leading-5"
			}`}>{heading || upperText || $getUpperTextByTimePeriod()}</span>
		{#if !upperText}
			<span class="u-absolute u-top-0 -u-right-6">
				{@html getIconByTimePeriod()}
			</span>
		{/if}
	</p>
	<p
		class={`u-text-black-third u-line-clamp-1 u-text-sm ${
			variant === variantEnum.MINIFIED
				? "u-leading-4 u-font-bold sm:u-font-normal"
				: "u-leading-5 u-font-normal"
		}`}>
		{subHeading || $getSubHeading($upsyContext, config.greetings)}
	</p>
</div>
