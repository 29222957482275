<script lang="ts">
	import variantType, { variantEnum } from "../../types/variant";

	export let variant: variantType = variantEnum.MINIFIED;
	let classZ = "";
	export { classZ as class };
</script>

<div
	class={`${
		variant === variantEnum.MINIFIED
			? "u-w-[120px] u-h-[160px] sm:u-w-[160px] sm:u-h-[168px]"
			: "u-w-[185px] u-h-[189.55px]"
	} u-animate-pulse u-box-border u-cursor-pointer u-shadow-upsy u-rounded-[10px] u-bg-card-gray  u-grow-0 u-shrink-0 u-overflow-hidden ${classZ}`}>
	<div
		class=" u-h-[51.2%] u-flex u-justify-center u-items-center u-overflow-hidden u-bg-gray-300 dark:u-bg-gray-700" />
	<div class="u-h-[27.5%] u-flex u-flex-col u-justify-center">
		<div
			class="u-mx-2 u-w-[90%] u-h-2 u-bg-gray-200 u-rounded-full u-dark:bg-gray-700" />
		<div
			class="u-mx-2 u-mt-2 u-w-[70%] u-h-2 u-bg-gray-200 u-rounded-full u-dark:bg-gray-700" />
	</div>
	<hr
		class="u-block u-w-full u-m-0 u-p-0 u-border-0 u-border-t-[1px] u-border-dash-gray u-border-dashed" />
	<div
		class="u-w-full u-h-[20%] u-bg-card-gray u-flex u-justify-between u-flex-wrap u-px-2 u-items-center">
		<div class="u-w-[47%] u-h-2 u-bg-gray-200 u-rounded-full u-dark:bg-gray-700" />
	</div>
</div>
