import { IZendesk } from "../../types/externalLibraries";

const moveChat = () => {
	if (!window.zE) {
		setTimeout(moveChat, 200);
		return;
	}
	const zE = window.zE as IZendesk;
	zE("messenger", "hide");
};

export const openChat = (closeWidget: () => void) => {
	if (!window.zE) {
		setTimeout(openChat.bind(closeWidget), 200);
		return;
	}
	const zE = window.zE as IZendesk;
	zE("messenger", "open");
	zE("messenger", "show");
	closeWidget();
};

export const initializeChat = () => {
	moveChat();
};
