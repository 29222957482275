const moveChat = () => {
	if (!window.$zopim || !window.$zopim.livechat || !window.$zopim.livechat.window) {
		setTimeout(moveChat, 200);
		return;
	}
	const $zopim = window.$zopim;
	$zopim(function () {
		$zopim.livechat.window.setPosition("bl");
		$zopim.livechat.hideAll();
	});
};

export const openChat = (closeWidget: () => void) => {
	if (!window.$zopim || !window.$zopim.livechat || !window.$zopim.livechat.window) {
		setTimeout(openChat, 200);
		return;
	}
	const $zopim = window.$zopim;
	window.$zopim(function () {
		$zopim.livechat.window.show();
		closeWidget();
		$zopim.livechat.window.onHide(() => $zopim.livechat.hideAll());
	});
};

export const initializeChat = () => {
	moveChat();
};
