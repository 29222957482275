import { writable } from "svelte/store";
import { cookies } from "../utils/cookieManager";

export const useMinimizeMode = (minimizedMode = false) => {
	const cookie = {
		key: "_upsy_should_skip_minimize_mode",
		value: "true"
	};
	const shouldSkipMinimizeMode = cookies.getCookie(cookie.key);
	if (cookie.value === shouldSkipMinimizeMode) {
		cookies.setCookie(cookie.key, cookie.value);
		minimizedMode = false;
	}
	const { subscribe, update } = writable<boolean>(minimizedMode);
	const updateMinimizeModeTo = (value: boolean) => {
		if (value !== false) {
			cookies.deleteCookie(cookie.key);
		} else {
			cookies.setCookie(cookie.key, cookie.value);
		}
		return update(() => value);
	};
	return { isMinimizedMode: { subscribe }, updateMinimizeModeTo };
};
