import { joinURL } from "./url";

export const getUrlByVersion = (url: string, version: string) =>
	url.replace("{}", version);

export const getStaticCSSURL = (baseUrl: string) => {
	return joinURL(baseUrl, "upsy.css");
};

export const getStoreConfigsApiURL = (baseUrl: string, customerId: string) => {
	return joinURL(baseUrl, "stores", customerId, "configs");
};

export const getStaticJSURL = (baseUrl: string) => {
	return joinURL(baseUrl, "upsy.js");
};

export const getInitAnalyticsEventURL = (
	baseUrl: string,
	tenantId: string,
	sessionId: string,
	key: string
) => {
	return joinURL(
		baseUrl,
		"rooms",
		tenantId,
		"chats",
		sessionId,
		"init",
		`?code=${key}`
	);
};
export const getAnalyticsEventURL = (
	baseUrl: string,
	tenantId: string,
	sessionId: string,
	key: string
) => {
	return joinURL(
		baseUrl,
		"rooms",
		tenantId,
		"chats",
		sessionId,
		"event",
		`?code=${key}`
	);
};

export const getUpSellURL = (
	baseUrl: string,
	storeId: string,
	productId: string,
	customFilter?: string
) => {
	return joinURL(
		baseUrl,
		"stores",
		storeId,
		"products",
		productId,
		"up-sell",
		customFilter ? `?customFilter=${customFilter}` : ""
	);
};

export const getCrossSellURL = (
	baseUrl: string,
	storeId: string,
	productId: string,
	customFilter?: string
) => {
	return joinURL(
		baseUrl,
		"stores",
		storeId,
		"products",
		productId,
		"cross-sell",
		customFilter ? `?customFilter=${customFilter}` : ""
	);
};

export const getFallbackURL = (
	baseUrl: string,
	storeId: string,
	productId: string,
	customFilter?: string
) => {
	return joinURL(
		baseUrl,
		"stores",
		storeId,
		"products",
		productId,
		"fallback",
		customFilter ? `?customFilter=${customFilter}` : ""
	);
};
export const getRelatedURL = (baseUrl: string, storeId: string, productId: string) => {
	return joinURL(baseUrl, "stores", storeId, "products", productId, "related");
};

export const getNewArrivalURL = (baseUrl: string, storeId: string) => {
	return joinURL(baseUrl, "stores", storeId, "products", "new");
};

export const getDailyDealsURL = (
	baseUrl: string,
	storeId: string,
	categoryName?: string,
	customFilter?: string,
	page = 0,
	hitsPerPage = 30
) => {
	return joinURL(
		baseUrl,
		"stores",
		storeId,
		"products",
		"discount",
		categoryName ? `?categories=${encodeURIComponent(categoryName)}` : "",
		customFilter ? `?customFilter=${customFilter}` : "",
		`?page=${page}`,
		`?hitsPerPage=${hitsPerPage}`
	);
};

export const getFeedbackURL = (baseUrl: string, storeId: string) => {
	return joinURL(baseUrl, "stores", storeId, "feedbacks");
};

export const getAbTestEventURL = (
	baseUrl: string,
	storeId: string,
	experimentId: string
) => {
	return joinURL(
		baseUrl,
		"stores",
		storeId,
		"experiments",
		experimentId,
		"ab-test",
		"collect"
	);
};
