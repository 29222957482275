/*
 ** Chat handler name : zendesk/sunshine-conversations-web(Smooch Web Messenger)
 ** Website: https://www.zendesk.com/platform/conversations/
 ** API & SDK documentation: https://docs.smooch.io/guide
 ** API & SDK documentation for javascript : https://docs.smooch.io/guide/web-messenger/
 */

const chatContainerId = "web-messenger-container";

function showChatContainer() {
	const chatContainer = document.getElementById(chatContainerId);
	if (chatContainer) chatContainer.style.display = "initial";
}

function hideChatContainer() {
	const chatContainer = document.getElementById(chatContainerId);
	if (chatContainer) chatContainer.style.display = "none";
}

const moveChat = () => {
	if (!window.Smooch) {
		setTimeout(moveChat, 200);
		return;
	}

	window.Smooch.on("widget:closed", function () {
		hideChatContainer();
	});

	window.Smooch.on("widget:opened", function () {
		showChatContainer();
	});

	window.Smooch.on("ready", function () {
		window.Smooch.close();
	});
};

export const openChat = (closeWidget: () => void) => {
	window.Smooch.open();
	closeWidget();
};

export const initializeChat = () => {
	moveChat();
};
