const moveChat = () => {
	if (!window.olark) {
		setTimeout(moveChat, 200);
		return;
	}
	const olark = window?.olark;
	olark("api.box.hide");
	olark("api.box.onShrink", function () {
		olark("api.box.hide");
	});
};

export const openChat = (closeWidget: () => void) => {
	if (!window?.olark) {
		setTimeout(openChat, 200);
		return;
	}
	const olark = window?.olark;
	olark("api.box.expand");
	closeWidget();
};

export const initializeChat = () => {
	moveChat();
};
