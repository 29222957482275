export enum activeWidgetScreenEnum {
	WIDGETEXPANDED = "WIDGETEXPANDED",
	WIDGETMINIFIED = "WIDGETMINIFIED",
	GREETINGLG = "GREETINGLG",
	GREETINGSM = "GREETINGSM",
	FEEDBACK = "FEEDBACK",
	THANKYOU = "THANKYOU",
	ANYQUESTION = "ANYQUESTION"
}

export type activeWidgetScreenType = `${activeWidgetScreenEnum}` | null;
