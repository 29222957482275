import { ISmartsuppAPI } from "../../types/externalLibraries";

const upsyCheckIfSmartsuppLoaded = (callback: (SmartsuppAPI: ISmartsuppAPI) => void) => {
	if (!window.smartsupp) setTimeout(() => upsyCheckIfSmartsuppLoaded(callback), 1000);
	else callback(window.smartsupp);
};

const openSmartsuppChat = (SmartsuppAPI: ISmartsuppAPI, closeWidget: () => void) => {
	SmartsuppAPI("chat:open");
	closeWidget();
};

const whenSmartsuppLoaded = (SmartsuppAPI: ISmartsuppAPI) => {
	closeSmartsuppChat(SmartsuppAPI);
};

const closeSmartsuppChat = (SmartsuppAPI: ISmartsuppAPI) => {
	SmartsuppAPI("chat:hide");
};

export const openChat = (closeWidget: () => void) => {
	upsyCheckIfSmartsuppLoaded((SmartsuppAPI: ISmartsuppAPI) =>
		openSmartsuppChat(SmartsuppAPI, closeWidget)
	);
};

export const initializeChat = () => {
	upsyCheckIfSmartsuppLoaded(whenSmartsuppLoaded);
};
