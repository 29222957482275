import { get as getStore } from "svelte/store";
import App from "../App.svelte";
import { locale } from "../i18n/i18n";
import { activeWidgetScreen } from "../stores/activeWidgetScreen";
import { storeConfig } from "../stores/storeConfig";
import { upsyContext } from "../stores/upsyContext";
import { IConfigObject } from "../types/sdk";
import { getEnvironmentEndpoints } from "../utils/getEnvironmentEndpoints";
import { getStaticCSSURL } from "../utils/getUpsyUrls";
import { removeWidget } from "../utils/removeWidget";

export const preview = async (
	config: IConfigObject,
	parent: HTMLElement = document.body,
	environment = "production"
) => {
	//Remove existing widgets if exist
	removeWidget(parent);

	storeConfig.set({ ...config });

	environment = environment.trim();
	// Set up the elements
	const target: HTMLElement = document.createElement("div");
	const shadowDOM: ShadowRoot = target.attachShadow({ mode: "open" });
	target.className = "upsy-plugin";
	target.setAttribute("plugin-name", "upsy plugin 3.0");
	parent.insertAdjacentElement("beforeend", target);

	const { staticResourceURL } = getEnvironmentEndpoints(environment);
	const cssUrl = getStaticCSSURL(staticResourceURL);

	// Load up styles
	const stylesPromise = new Promise<void>(resolve => {
		const linkElement = document.createElement("link");
		linkElement.setAttribute("rel", "stylesheet");
		linkElement.setAttribute("href", cssUrl);
		shadowDOM.appendChild(linkElement);
		linkElement.onload = () => {
			resolve();
		};
	});

	try {
		// Wait for styles to load
		await stylesPromise;
		//Add widget locale base on cofiguration language value
		if (config.language === "default") {
			locale.set("");
		} else if (config.language) {
			locale.set(config.language);
		}
		new App({
			target: shadowDOM,
			props: {
				targetElement: target,
				environment,
				positionType: "absolute",
				previewMode: true
			}
		});

		return {
			updateConfig: (config: Partial<IConfigObject>) => {
				if (config.language === "default") {
					locale.set("");
				} else if (config.language) {
					locale.set(config.language);
				}
				storeConfig.update(prev => ({ ...prev, ...config }));
			},
			getWidgetScreen: () => {
				return getStore(activeWidgetScreen);
			},
			getWidgetContext: () => {
				return getStore(upsyContext);
			},
			setWidgetScreen: (screen: string) => {
				activeWidgetScreen.update(prevScreen => screen as typeof prevScreen);
			},
			setWidgetContext: (context: string) => {
				upsyContext.update(prevContex => context as typeof prevContex);
			},
			subscribeScreen: activeWidgetScreen.subscribe,
			subscribeContext: upsyContext.subscribe
		};
	} catch (error) {
		console.error(error);
		return;
	}
};
