<style>
	.u-active-tab {
		position: relative;
	}
	.u-active-tab::after {
		content: "";
		position: absolute;
		width: 30px;
		bottom: -7px;
		left: 50%;
		height: 0;
		transform: translateX(-50%);
		border-bottom: 4px solid var(--accent-color, rgba(24, 157, 235, 1));
		border-radius: 4px;
	}
</style>

<script lang="ts">
	import { variantEnum } from "../../types/variant";
	import CloseIcon from "../shared/CloseIcon.svelte";
	import Header from "../shared/Header.svelte";
	import CategoryCard from "../shared/CategoryCard.svelte";
	import GreetingMini from "../shared/GreetingMini.svelte";
	import ProductCard from "../shared/ProductCard.svelte";
	import ShowBtn from "../shared/ShowBtn.svelte";
	import FaqItem from "./FaqItem.svelte";
	import { createEventDispatcher, getContext } from "svelte";
	import IPositionProps from "../../types/positionProps";
	import getPositionPropsToCss from "../../utils/positionPropsToCss";
	import ChatButton from "./ChatButton.svelte";
	import {
		GlobalContextKeyEnum,
		IUpsyContext,
		UpsyContextEnum
	} from "../../types/context";
	import { upsyContext } from "../../stores/upsyContext";
	import { postDisplayScreenEvent } from "../../utils/event";
	import { ifWidgetToLeftThenTransformPositions } from "../../utils/widgetPosition";
	import { isProductsLoadingSpinner } from "../../stores/loadingSpinner";
	import { upsyContexualProducts } from "../../stores/products";
	import { t } from "../../i18n/i18n";
	import { storeConfig } from "../../stores/storeConfig";
	import { IConfigObject, IFAQ } from "../../types/sdk";
	import { ISwipePayload, swipeAction } from "../../actions/swipeAction";
	import { swipeWidgetExpanded } from "../../utils/widgetSwipe";
	import ProductSkeletonLoader from "../shared/ProductSkeletonLoader.svelte";
	import SwipeIndicator from "../shared/SwipeIndicator.svelte";
	import { getConditionalRadiusForExapandedOrMinifiedWidget } from "../../utils/utils";

	const dispatch = createEventDispatcher();
	function swipeHandler(e: CustomEvent) {
		const detail = e.detail as ISwipePayload;
		const { swipeDirection } = detail;
		swipeDirection && swipeWidgetExpanded(swipeDirection, dispatch);
	}

	let upsyConfig: IConfigObject;
	$: upsyConfig = $storeConfig;
	const environment = getContext<string>(GlobalContextKeyEnum.ENVIRONMENT);
	export let positionProps: IPositionProps;
	export let previewMode = false;
	$: positionPropsTocss = getPositionPropsToCss({
		...positionProps,
		bottom: ($storeConfig.liftExpandedWidget || "0px") as IPositionProps["bottom"]
	});

	$: widgetToLeft = upsyConfig.widgetToLeft;
	$: customerCategories = $storeConfig.customerCategories || [];
	$: conditionalRadius = getConditionalRadiusForExapandedOrMinifiedWidget(
		widgetToLeft,
		$storeConfig.liftExpandedWidget
	);
	//A utility function to check if the widget is to the left or right. If it is to the left, it will transform the position props to the left.
	$: modifiedPositionPropsForGreetingMini = ifWidgetToLeftThenTransformPositions(
		widgetToLeft,
		{
			...positionProps,
			position: "absolute",
			bottom: "15px",
			right: "100%"
		}
	);

	$: tabs = [
		{
			label: $t("categories"),
			value: UpsyContextEnum.CATEGORY
		},
		{
			label: $t("new_arrivals"),
			value: UpsyContextEnum.NEW_ARRIVAL
		},
		{
			label: $t("daily_deals"),
			value: UpsyContextEnum.DAILY_DEALS
		},
		{
			label: $t("faqs"),
			value: UpsyContextEnum.FAQ
		}
	];

	$: {
		// When new arrival tab is disabled from the configuration, remove new arrival tab from the tabs list
		if ($storeConfig.isNewArrivalTabDisabled) {
			const index = tabs.findIndex(
				item => item.value == UpsyContextEnum.NEW_ARRIVAL
			);
			if (index > -1) {
				tabs.splice(index, 1);
			}
		}

		// When daily delas tab is disabled from the configuration, remove daily delas tab from the tabs list
		if ($storeConfig.isDailyDealsDisabled) {
			const index = tabs.findIndex(
				item => item.value == UpsyContextEnum.DAILY_DEALS
			);
			if (index > -1) {
				tabs.splice(index, 1);
			}
		}
	}

	function handleTabChange(tab: { label: string; value: IUpsyContext }) {
		$upsyContext = tab.value;
		!previewMode &&
			void postDisplayScreenEvent(upsyConfig, environment, $upsyContext);
	}

	$: isNewProduct = $upsyContext === UpsyContextEnum.NEW_ARRIVAL ? true : false;
	$: isSaleProduct = $upsyContext === UpsyContextEnum.DAILY_DEALS ? true : false;

	function getFilterFaqs(faqs: IFAQ[] = []) {
		const pinned = [];
		const unpinned = [];

		for (const faq of faqs) {
			faq.isPinded ? pinned.push(faq) : unpinned.push(faq);
		}

		return {
			pinned,
			unpinned
		};
	}
</script>

<div
	style={positionPropsTocss}
	class={`u-word-wrap-break-word u-touch-none u-flex u-flex-col u-font-inter u-fixed u-bg-background u-bottom-0 u-right-0 u-w-full min-w-490:u-w-[364px]  u-h-[calc(100%-80px)] u-max-h-[519px] u-pb-7 u-shadow-[0px_6px_12px_rgba(0,0,0,0.1)] ${conditionalRadius}`}>
	<div
		use:swipeAction
		on:swipe={swipeHandler}
		class="u-flex u-mx-6 u-relative u-py-[27.5px]">
		<SwipeIndicator />
		<CloseIcon on:click={() => dispatch("close")} />
	</div>
	<div class="u-mx-6 u-flex u-justify-between u-items-start">
		<Header variant={variantEnum.EXPANDED} {dispatch} />
		<ShowBtn
			btnText={$t("show_less")}
			on:click={() => dispatch("minimize")}
			variant={variantEnum.EXPANDED} />
	</div>
	<div class="u-mt-2 u-bg-white">
		<ul
			class={`u-flex ${
				tabs.length < 4 ? "u-justify-evenly" : "u-justify-between"
			} u-h-[50px] u-overflow-hidden u-px-6 u-w-full  u-items-center u-list-none u-font-inter u-font-medium u-text-xs u-leading-4 min-w-390:u-text-[13px] sm:u-text-sm u-text-sub-menu-gray`}>
			{#each tabs as tab (tab.value)}
				<li
					on:click={handleTabChange.bind(null, tab)}
					class={`${
						tabs.length < 4 ? "u-flex-1" : ""
					} u-relative u-w-max u-max-w-[25%] u-inline-block u-cursor-pointer u-select-none u-text-center ${
						$upsyContext === tab.value
							? "u-active-tab u-font-bold u-text-black-secondary"
							: ""
					}`}>
					<span class="u-line-clamp-2 u-inline-block u-tracking-wide"
						>{tab.label}</span>
				</li>
			{/each}
		</ul>
	</div>
	{#if $upsyContext === UpsyContextEnum.CATEGORY}
		<div
			class="u-hide-scroll u-overscroll-none u-h-full u-mt-5 u-pb-2 u-px-6 u-grid u-grid-cols-2 min-w-428:u-grid-cols-3 u-gap-[10px] u-overflow-y-scroll u-overflow-x-hidden">
			{#each customerCategories as category (category.order)}
				<CategoryCard
					class="!u-max-w-full !u-w-full sm:!u-w-[102px] sm:!u-max-w-[102px]"
					variant={variantEnum.EXPANDED}
					{category}
					{previewMode} />
			{:else}
				<p
					class="u-text-center u-text-gray-600 u-text-base u-col-span-3 u-self-center">
					{$t("no_categories_found")}
				</p>
			{/each}
		</div>
	{:else if $upsyContext === UpsyContextEnum.FAQ}
		<div
			class="u-overscroll-none u-hide-scroll u-flex u-flex-col u-px-6 u-mt-5 u-overflow-y-auto u-overflow-x-hidden ">
			{#each getFilterFaqs(upsyConfig.customQnA).pinned as faq, index (index)}
				<FaqItem {faq} {previewMode} />
			{/each}
			{#each getFilterFaqs(upsyConfig.customQnA).unpinned as faq, index (index)}
				<FaqItem {faq} {previewMode} />
			{/each}
		</div>
		<ChatButton
			on:openChat={() => dispatch("openChat")}
			on:close={() => dispatch("close")} />
	{:else if $isProductsLoadingSpinner}
		<div
			class="u-overscroll-none u-hide-scroll u-mt-5 u-pb-2 u-px-6 u-grid u-grid-cols-2 u-gap-[10px] u-overflow-y-scroll u-overflow-x-hidden">
			{#each [1, 2, 3, 4] as item (item)}
				<ProductSkeletonLoader
					class="!u-max-w-full !u-w-full"
					variant={variantEnum.EXPANDED} />
			{/each}
		</div>
	{:else if !$isProductsLoadingSpinner && $upsyContexualProducts.length > 0}
		<div
			class="u-overscroll-none u-hide-scroll u-mt-5 u-pb-2 u-px-6 u-grid u-grid-cols-2 u-gap-[10px] u-overflow-y-scroll u-overflow-x-hidden">
			{#each $upsyContexualProducts as product (product.productId)}
				<ProductCard
					class="!u-max-w-full !u-w-full"
					{product}
					{previewMode}
					{isNewProduct}
					{isSaleProduct}
					variant={variantEnum.EXPANDED} />
			{/each}
		</div>
	{:else}
		<div class="u-w-full u-h-full u-flex u-justify-center u-items-center">
			<p class="u-text-center u-text-gray-600 u-text-base">
				{$t("no_product_found")}!
			</p>
		</div>
	{/if}
	<GreetingMini
		on:chatNavigate
		on:click={() => dispatch("close")}
		on:minimize={() => dispatch("minimize")}
		on:close={() => dispatch("close")}
		class="!u-hidden min-w-490:!u-flex"
		positionProps={modifiedPositionPropsForGreetingMini} />
	<p class="u-absolute u-right-6 u-bottom-[6px]">
		<a
			href="https://upsyshopping.com/"
			target="_blank"
			class="u-no-underline u-text-xs u-font-extrabold u-text-gray-400">upsy</a>
	</p>
</div>
