export interface ICookieManager {
	getAllCookies(): Record<string, string>;
	getCookie(name: string): string;
	setCookie(
		name: string,
		value: string,
		days?: number,
		path?: string,
		domain?: string
	): void;
	deleteCookie(name: string, path?: string, domain?: string): void;
	deleteCookiesByPrefix(prefix: string, path?: string, domain?: string): void;
}

class CookieManager implements ICookieManager {
	// Singleton instance
	private static instance: CookieManager;

	// Private constructor to prevent direct instantiation
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	private constructor() {}

	// Method to get all cookies as an object
	getAllCookies(): Record<string, string> {
		return document.cookie
			.split(";")
			.reduce((cookies: Record<string, string>, cookie: string) => {
				const [name, value] = cookie.split("=").map(c => c.trim());
				try {
					cookies[name] = decodeURIComponent(value);
				} catch (_) {
					cookies[name] = value;
				}
				return cookies;
			}, {});
	}

	// Method to get a specific cookie by name
	public getCookie(name: string): string {
		const cookies = this.getAllCookies();
		return cookies[name] || "";
	}

	// Method to set a cookie
	public setCookie(
		name: string,
		value: string,
		days = 7,
		path = "/",
		domain?: string
	): void {
		// 864e5 is the number of milliseconds in a day (86400000)
		const expires = new Date(Date.now() + days * 864e5).toUTCString();
		let cookieString = "";
		try {
			cookieString += `${name}=${encodeURIComponent(
				value
			)};expires=${expires};path=${path}`;
		} catch (_) {
			cookieString += `${name}=${value};expires=${expires};path=${path}`;
		}
		if (domain) {
			cookieString += `;domain=${domain}`;
		}
		document.cookie = cookieString;
	}

	// Method to delete a cookie by name
	public deleteCookie(name: string, path = "/", domain?: string): void {
		// 0 milliseconds since the epoch corresponds to 01 Jan 1970 00:00:00 UTC
		const expires = new Date(0).toUTCString();
		let cookieString = `${name}=;expires=${expires};path=${path}`;
		if (domain) {
			cookieString += `;domain=${domain}`;
		}
		document.cookie = cookieString;
	}

	// Method to delete cookies by prefix using startsWith
	public deleteCookiesByPrefix(prefix: string, path = "/", domain?: string): void {
		const cookies = this.getAllCookies();
		Object.keys(cookies).forEach(cookieName => {
			if (cookieName.startsWith(prefix)) {
				this.deleteCookie(cookieName, path, domain);
			}
		});
	}

	// Static method to get the Singleton instance
	public static getInstance(): CookieManager {
		if (!CookieManager.instance) {
			CookieManager.instance = new CookieManager();
		}
		return CookieManager.instance;
	}
}

export const cookies = CookieManager.getInstance();
