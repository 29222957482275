import { upsyFlowSelectorTypeEnum } from "../types/flow";
import { IConfigObject, Iflow } from "../types/sdk";
import { getPageTypeByUpsySelector } from "./pageType";

const flowTagFound = (flow: Iflow) =>
	flow?.trigger?.value === getPageTypeByUpsySelector();

const flowClassFound = (flow: Iflow) => {
	if (flow?.trigger?.value) {
		return document.querySelector(flow.trigger.value);
	}
	return false;
};

const flowUrlMatch = (flow: Iflow) => {
	if (flow?.trigger?.value) {
		return window.location.pathname.indexOf(flow.trigger.value) !== -1;
	}
	return false;
};

const flowExtendedUrlMatch = (flow: Iflow) => {
	if (flow?.trigger?.value) {
		return window.location.href.indexOf(flow.trigger.value) !== -1;
	}
	return false;
};

const hidingFlows = (flows: Iflow[]) =>
	flows.filter(flow => flow?.trigger?.type === upsyFlowSelectorTypeEnum.HIDE_ON_URL);
const hidingFlowUrlMatch = (hidingFlow: Iflow) => flowExtendedUrlMatch(hidingFlow);

export function getFlowFromUrl(upsyConfig: IConfigObject) {
	const flows = upsyConfig.flows || [];
	for (const flow of flows) {
		const flowTriggerType = flow.trigger.type;
		switch (flowTriggerType) {
			case upsyFlowSelectorTypeEnum.UPSY_SELECTOR: {
				if (flowTagFound(flow)) return flow;
				break;
			}
			case upsyFlowSelectorTypeEnum.CLASS_SELECTOR: {
				if (flowClassFound(flow)) return flow;
				break;
			}

			case upsyFlowSelectorTypeEnum.URL_SELECTOR: {
				if (flowUrlMatch(flow)) return flow;
				break;
			}
		}
	}

	return null;
}

export function isUpsyHideOnThisUrl(flows: Iflow[]) {
	for (const hidingFlow of hidingFlows(flows)) {
		if (hidingFlowUrlMatch(hidingFlow)) return true;
	}
	return false;
}
