export const openChat = (closeWidget: () => void) => {
	window.tidioChatApi?.show();
	window.tidioChatApi?.open();
	closeWidget();
};

export const initializeChat = () => undefined;

if (window.tidioChatApi) {
	window.tidioChatApi?.on("ready", onTidioChatApiReady);
} else {
	document.addEventListener("tidioChat-ready", onTidioChatApiReady);
}

function onTidioChatApiReady() {
	window.tidioChatApi?.hide();
	window.tidioChatApi?.on("close", function () {
		window.tidioChatApi?.hide();
	});
}
