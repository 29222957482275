export function sendAnalytics(url: string, data: BodyInit | null | undefined) {
	// if (navigator.sendBeacon) {
	// Use navigator.sendBeacon for asynchronous, reliable data transfer
	//navigator.sendBeacon always send POST reqeust with content-type: text/plain
	// navigator.sendBeacon(url, data);
	// return;
	// } else {
	// Fallback to fetch for broader browser compatibility
	void fetch(url, {
		method: "POST",
		body: data,
		headers: {
			"Content-Type": "text/plain"
		},
		keepalive: true // Maintain connection for potential response
	}).catch(error => {
		console.warn("Error sending upsy a/b events: ", error);
	});
	return;
	// }
}
