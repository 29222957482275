export enum GlobalContextKeyEnum {
	ENVIRONMENT = "environment"
}

export enum UpsyContextEnum {
	UP_SELL = "UP_SELL",
	CROSS_SELL = "CROSS_SELL",
	RELATED_SELL = "RELATED_SELL",
	CATEGORY = "CATEGORY",
	NEW_ARRIVAL = "NEW_ARRIVAL",
	DAILY_DEALS = "DAILY_DEALS",
	FAQ = "faq"
}

export type IUpsyContext = `${UpsyContextEnum}` | null;
