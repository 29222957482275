export function setStorage(name: string, value: unknown) {
	const stringifyValue = JSON.stringify(value);
	localStorage.setItem(name, stringifyValue);
}

export function getStorage(name: string) {
	const stringifyItem = localStorage.getItem(name);
	if (!stringifyItem) {
		return [];
	}
	const parseItem: unknown = JSON.parse(stringifyItem);
	return parseItem;
}
