import { UPSY_PURCHASE_COMPLETED_COOKIE_KEY } from "../../constants";
import { getLocalStorageCartItems, getOrder, getOrderId, ICartProduct } from "../cart";
import { cookies } from "../cookieManager";
import { getDataLayer } from "../dataLayer";

import { sendAnalytics } from "./sender";

export function sendAbTestInitEvent(url: string, payload: Record<string, unknown>) {
	/**  Payload example
	 * 	{
	 *
	 * 	   "event":"upsy_ab_page_view"
	 *     "experimentId": "",
	 *     "sessionId": "",
	 *     "tenantdId": "",
	 *     "isUpsyHidden": true/false,
	 * }
	 */
	const event = "upsy_ab_page_view";
	getDataLayer().push({
		event,
		upsyAbTest: {
			...payload
		}
	});
	void sendAnalytics(url, JSON.stringify({ event, ...payload }));
}
export function sendAbTestPurchaseEvent(
	url: string,
	payload: Record<string, unknown>,
	isAttachPurchaseProductsInfo = false
) {
	if (isPurchaseEventSent()) {
		console.warn("Prevent sending duplicate a/b test purchase event");
		return;
	}
	const orderId = getOrderId();
	const purchase = getPurchase();
	const purchaseAmount = calculatePurchaseAmount(purchase);
	/** Payload example
	 * 	{
	 * 	   "event":"upsy_ab_purchase"
	 *     "experimentId": "",
	 *     "sessionId": "",
	 *     "tenantdId": "",
	 *     "isUpsyHidden": true/false,
	 *     "orderId":"",
	 *     "purchaseAmount":123.23,
	 * }
	 */
	//This check is for, does this store enable(configured via fe configs) send purchase products details along with ab test purchase event payload
	if (isAttachPurchaseProductsInfo === true) {
		payload.products = purchase;
	}
	payload = {
		...payload,
		orderId,
		purchaseAmount: purchaseAmount.toFixed(2)
	};
	const event = "upsy_ab_purchase";
	getDataLayer().push({
		event,
		upsyAbTest: {
			...payload,
			purchaseAmount: parseFloat(purchaseAmount.toFixed(2))
		}
	});
	void sendAnalytics(url, JSON.stringify({ event, ...payload }));
}

function isEmptyArray(arr: unknown[]) {
	return Array.isArray(arr) && arr.length ? false : true;
}

function getPurchase() {
	const order = getOrder();
	if (!isEmptyArray(order)) {
		return order;
	}
	return getLocalStorageCartItems();
}
function calculatePurchaseAmount(purchase: ICartProduct[]) {
	const totalPurchaseAmount = purchase.reduce((a, b) => {
		const price = b.unit_price ? parseFloat(`${b.unit_price}`) : 0;
		const quantity = b.quantity ? parseInt(`${b.quantity}`) : 0;
		return a + price * quantity;
	}, 0);
	return totalPurchaseAmount;
}

function isPurchaseEventSent() {
	return Boolean(cookies.getCookie(UPSY_PURCHASE_COMPLETED_COOKIE_KEY));
}
