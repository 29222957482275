export default {
	greeting_lg: "Waar denk je aan",
	new: "nieuwe",
	sale: "uitverkoop",
	chat_btn_text: "Chat met ons",
	show_less: "Minimaliseer",
	see_all: "alles zien",
	submit: "indienen",
	feedback_textInBubble: "Is het me gelukt om je te helpen met je aankoop?",
	feedback_positiveFeedback: "Dank je! Wat vond je leuk?",
	feedback_negativeFeedback: "Dank je! Wat kunnen we verbeteren?",
	loading: "het laden",
	no_product_found: "Sorry, er zijn geen producten gevonden",
	categories: "Categorieën",
	new_arrivals: "Nieuw binnen",
	daily_deals: "Dagelijkse deals",
	similar_products: "Vergelijkbare producten",
	faqs: "FAQs",
	popular_categories: "Populaire categorieën",
	newest_products: "Nieuwste producten",
	best_deals: "Beste deals",
	others_also_purchased: "Anderen kochten ook",
	have_you_seen_these: "Heb je deze gezien",
	any_questions: "Nog vragens",
	frequently_asked_questions: "Veelgestelde vragen",
	thank_you: "Dank je",
	good_morning: "Goedemorgen",
	good_noon: "Goedemiddag",
	good_afternoon: "Goedemiddag",
	good_evening: "goedeavond",
	good_night: "Welterusten",
	no_categories_found: "Sorry! geen categorieën gevonden",
	browse_new_products: "Blader door nieuwe producten",
	do_u_need_help: "Heb je hulp nodig",
	sidebar_cta_text_for_daily_deals: "Zie aanbiedingen",
	sidebar_cta_text_for_up_sell: "Zie opties",
	sidebar_cta_text_for_cross_sell: "Anderen kochten",
	sidebar_cta_text_for_new_arrival: "Nieuwkomers",
	sidebar_cta_text_for_front_page: "Snel winkelen"
};
