export const UPSY_API_BASE_URL = "https://api.upsyshopping.com/{}/";
export const UPSY_API_BASE_URL_EU_WEST_1 =
	"https://cx797cxp22.execute-api.eu-west-1.amazonaws.com/prod/v1/";
export const UPSY_STATIC_BASE_URL = "https://upsy-widget.upsyshopping.com/static/";
export const UPSY_ANALYTICS_BASE_URL = "https://upsy-backend-prod.azurewebsites.net/api/";
export const UPSY_ANALYTICS_KEY =
	"XaQ8cBzy6MqfL2tARCTaPnnaNxJQaL9zYsTUIhLm43ztWwXgRvL0Mw==";

export const UPSY_API_BASE_URL_STAGING = "https://api-staging.upsyshopping.com/{}/";
export const UPSY_API_BASE_URL_STAGING_EU_WEST_1 =
	" https://y0dktb8d89.execute-api.eu-west-1.amazonaws.com/staging/v1/";
export const UPSY_STATIC_BASE_URL_STAGING =
	"https://upsy-widget-staging.upsyshopping.com/static/";
export const UPSY_ANALYTICS_BASE_URL_STAGING =
	"https://upsy-backend-dev.azurewebsites.net/api/";
export const UPSY_ANALYTICS_KEY_STAGING =
	"oYDWXeqNpuDQeNrWq9AKwHZKsNPN7WTApOKanRXuQ3kEOBds2YQQvg==";

export const UPSY_API_BASE_URL_LOCAL = "http://localhost:3000/dev/{}/"; //"https://api-staging.upsyshopping.com/v1/";
export const UPSY_API_BASE_URL_LOCAL_EU_WEST_1 =
	"https://y0dktb8d89.execute-api.eu-west-1.amazonaws.com/staging/v1/";
export const UPSY_STATIC_BASE_URL_LOCAL = "http://localhost:8000/static/";
export const UPSY_ANALYTICS_BASE_URL_LOCAL =
	"https://upsy-backend-dev.azurewebsites.net/api/";
export const UPSY_ANALYTICS_BASE_URL_FEATURE =
	"https://upsy-backend-dev.azurewebsites.net/api/";
export const UPSY_ANALYTICS_KEY_FEATURE =
	"oYDWXeqNpuDQeNrWq9AKwHZKsNPN7WTApOKanRXuQ3kEOBds2YQQvg==";

export const SESSION_COOKIE_KEY = "upsypx";
export const CLOSE_BY_USER_COOKIE_KEY = "upsyClosedByUser";

export const STORAGE_UPSY_PRODUCT_UPSELL_KEY = "upsyProductUpsells";
export const STORAGE_UPSY_CART_KEY = "upsyCart";

export const UPSY_PURCHASE_COMPLETED_COOKIE_KEY = "upsyPurchaseCompleted";

export const UPSY_STATIC_BASE_URL_FEATURE =
	"https://upsy-widget-{}.upsyshopping.com/static/";

export const UPSY_TEST_MODE_COOKIE_KEY = "upsytest";
export const UPSY_TEST_MODE_URL_KEY = "upsytesti";
