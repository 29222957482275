import IPositionProps from "../types/positionProps";
/**
 * If the widget is to the left of the screen, then transform the right position to a left position
 * @param {boolean|null|undefined} widgetToLeft - boolean|null|undefined
 * @param {IPositionProps} positionProps - IPositionProps = {
 * @returns an object with the properties of the positionProps object, except for the right property.
 */
export function ifWidgetToLeftThenTransformPositions(
	widgetToLeft: boolean | null | undefined,
	positionProps: IPositionProps
): IPositionProps {
	if (widgetToLeft) {
		const { right, ...restProps } = positionProps;
		return { ...restProps, left: right };
	}
	return { ...positionProps };
}

/**
 * If the configuration have liftUpsy/liftUpsyDesktop value then lift upsy up base on the value
 * @param {string | undefined} liftUpsy - string | undefined
 * @param {string | undefined} liftUpsyDesktop - string | undefined
 * @param {number} currentScreenWidth - The current screen width.
 * @param {IPositionProps} positionProps - The position props that you want to lift upsy.
 * @param [smallScreenWidthThreshold=740] - The threshold of the screen width to determine whether the
 * screen is small or not.
 */
export function ifUpsyHaveToLiftThenLiftUpsy(
	liftUpsy: string | undefined,
	liftUpsyDesktop: string | undefined,
	currentScreenWidth: number,
	positionProps: IPositionProps,
	smallScreenWidthThreshold = 740
): IPositionProps {
	/**
	 * If the configuration have liftUpsy/liftUpsyDesktop value then lift upsy up base on the value.
	 * liftUpsy use for small screen ( screen width equal or less smallScreenWidthThreshold (default is 740px))
	 * listUpsyDesktop use for bigger screen (screen width bigger than smallScreenWidthThreshold ( default is 740px))
	 */
	const { bottom, ...restProps } = positionProps;
	if (smallScreenWidthThreshold >= currentScreenWidth) {
		return {
			...restProps,
			bottom: (liftUpsy ? liftUpsy : bottom) as IPositionProps["bottom"]
		};
	} else {
		return {
			...restProps,
			bottom: (liftUpsyDesktop
				? liftUpsyDesktop
				: bottom) as IPositionProps["bottom"]
		};
	}
}
