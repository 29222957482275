// This handler is not currently used - it was found in old code, and included in the case this "fc" would be used again.

const hideChat = function () {
	if (!window.$crisp) {
		setTimeout(hideChat, 500);
		return;
	}
	window.$crisp.push(["do", "chat:hide"]);
};

export const openChat = (closeWidget: () => void) => {
	if (!window.$crisp) {
		setTimeout(openChat, 500);
		return;
	}
	const $crisp = window.$crisp;

	// open Crisp chat && show
	$crisp.push(["do", "chat:open"]);
	$crisp.push(["do", "chat:show"]);

	closeWidget();

	// hide Crisp when chat closed
	$crisp.push(["on", "chat:closed", onChatClosed]);

	function onChatClosed() {
		$crisp.push(["do", "chat:hide"]);
	}
};

export const initializeChat = () => {
	if (window.jQuery) {
		window.jQuery(document).ready(function () {
			hideChat();
		});
	} else {
		document.addEventListener("DOMContentLoaded", function () {
			hideChat();
		});
	}
};
