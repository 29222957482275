export default {
	greeting_lg: "Mitä sinulla on mielessäsi",
	new: "Uutuus",
	sale: "Tarjous",
	chat_btn_text: "Keskustele kanssamme",
	show_less: "Pienennä",
	see_all: "Laajenna",
	submit: "Lähetä",
	feedback_textInBubble: "Olinko avuksi?",
	feedback_positiveFeedback: "Mistä tykkäsit?",
	feedback_negativeFeedback: "Olisiko jotain parannettavaa?",
	loading: "Ladataan",
	no_product_found: "Anteeksi, tuotteita ei löytynyt",
	categories: "Kategoriat",
	new_arrivals: "Uutta",
	daily_deals: "Tarjoukset",
	similar_products: "Samankaltaisia tuotteita",
	faqs: "Kysy",
	popular_categories: "Suositut kategoriat",
	newest_products: "Uutuustuotteet",
	best_deals: "Parhaat tarjoukset",
	others_also_purchased: "Muut ostivat myös",
	have_you_seen_these: "Oletko nähnyt näitä",
	any_questions: "Kysymyksiä",
	frequently_asked_questions: "Usein kysytyt kysymykset",
	thank_you: "Kiitos",
	good_morning: "Huomenta!",
	good_noon: "Päivää!",
	good_afternoon: "Päivää!",
	good_evening: "Iltaa!",
	good_night: "Iltaa!",
	no_categories_found: "Anteeksi, kategorioita ei löytynyt!",
	browse_new_products: "Selaile uutuustuotteita",
	do_u_need_help: "Tarvitsetko apua",
	sidebar_cta_text_for_daily_deals: "Katso tarjoukset",
	sidebar_cta_text_for_up_sell: "Katso vaihtoehdot",
	sidebar_cta_text_for_cross_sell: "Muut ostivat",
	sidebar_cta_text_for_new_arrival: "Uutta",
	sidebar_cta_text_for_front_page: "Pikahaku"
};
