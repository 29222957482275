const getChatFrame = () => {
	return (
		(document.getElementById("shopify-chat") as HTMLIFrameElement | null) ||
		(document.getElementById("dummy-chat-button-iframe") as HTMLIFrameElement | null)
	);
};

const moveChat = () => {
	const chatFrame = getChatFrame();
	if (!chatFrame) {
		setTimeout(moveChat, 200);
		return;
	}
	chatFrame.hidden = true;
};
const registerChatCloseHandler = () => {
	const chatFrame = getChatFrame();
	const chatFrameDoc = chatFrame && chatFrame.firstElementChild?.shadowRoot;
	const closeBtn =
		chatFrameDoc &&
		(chatFrameDoc.querySelector("button.chat-app--close-button") ||
			chatFrameDoc.querySelector("button[type='button'][name='closeChat']"));
	if (!closeBtn) {
		setTimeout(registerChatCloseHandler, 60);
		return;
	}

	closeBtn.addEventListener("click", () => {
		chatFrame.hidden = true;
	});
};

export const openChat = (closeWidget: () => void) => {
	const chatFrame = getChatFrame();
	if (!chatFrame) {
		console.warn("Shopify Default Chat is not loaded yet");
		return;
	}
	const chatFrameDoc = chatFrame.firstElementChild?.shadowRoot;
	const chatOpenBtn =
		chatFrameDoc && chatFrameDoc.querySelector('button[data-spec="toggle-button"]');
	closeWidget && typeof closeWidget == "function" && closeWidget();
	if (chatOpenBtn) {
		(chatOpenBtn as HTMLElement).click();
	}
	chatFrame.hidden = false;
	registerChatCloseHandler();
};

export const initializeChat = () => {
	moveChat();
};
