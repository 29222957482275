import { derived } from "svelte/store";
import { IConfigObject } from "../types/sdk";
import { locale } from "../i18n/i18n";
import { trimSlashes } from "./url";

export function getProductId() {
	let productId: string | null = "";
	let products = document.querySelectorAll(".upseller_product .product_id");

	if (products.length) {
		for (let i = 0; i < products.length; i++) {
			const last = i + 1;

			if (last === products.length) {
				productId = products[i].textContent;
			}
		}
	} else {
		products = document.querySelectorAll(".product_id");

		if (products.length) {
			for (let i = 0; i < products.length; i++) {
				const last = i + 1;

				if (last === products.length) {
					productId = products[i].textContent;
				}
			}
		} else {
			const priceElement = document.querySelector(".price-final_price");
			if (priceElement) {
				const id = priceElement.getAttribute("data-product-id");
				if (id !== null) {
					productId = id;
				}
			}
		}
	}
	return (productId || "").trim();
}

export function getProductNameFromContext() {
	let productName: string | null = "";
	const upsyProductName = document.querySelector(".upseller_product .name");
	if (upsyProductName) {
		productName = upsyProductName.textContent;
	} else {
		const simplePid = document.querySelector(".name");
		if (simplePid) {
			productName = simplePid.textContent;
		}
	}
	return (productName || "").trim();
}

export const generateProductUrl =
	(locale?: string) =>
	(
		permalink: string,
		configs: IConfigObject,
		baseUrl: string = window.location.origin
	) => {
		const { productUrlFormat } = configs;
		const baseLanguage = productUrlFormat?.baseLanguage;

		// Parse the permalink. If it's a full URL, this will handle it; if it's just a path, it will default to baseUrl.
		let parsedUrl = new URL(permalink, baseUrl);

		// Replace the origin of the parsedUrl with the current page's origin (baseUrl)
		parsedUrl = new URL(parsedUrl.pathname + parsedUrl.search, baseUrl);

		// Get the URL path without leading/trailing slashes
		const urlPath = trimSlashes(parsedUrl.pathname || "");

		// Return early if the locale matches the base language or no locale is provided
		if (!locale || locale === baseLanguage) {
			return parsedUrl.toString();
		}

		// Handle pathPrefix case
		if (
			productUrlFormat?.type === "pathPrefix" &&
			productUrlFormat.pathPrefix?.includes("{lang}")
		) {
			const pathWithPrefix = `${trimSlashes(
				productUrlFormat.pathPrefix.replace("{lang}", locale)
			)}/${urlPath}`;
			return new URL(pathWithPrefix + parsedUrl.search, baseUrl).toString();
		}

		// Handle queryParam case
		if (
			productUrlFormat?.type === "queryParam" &&
			typeof productUrlFormat.queryParam?.key === "string"
		) {
			parsedUrl.searchParams.set(productUrlFormat.queryParam.key, locale);
		}

		// Return the final URL with replaced origin and updated locale (if needed)
		return parsedUrl.toString();
	};

export const generateLocalizedProductUrl = derived(locale, generateProductUrl);
