import { abTestCookies } from "./cookieManager";

export type VariantActionFunc = (options?: {
	upsyRoot: HTMLElement;
	updateIsChatInitDisabled: (value: boolean) => void;
}) => void;
export interface Ivariant {
	id: number;
	name: string;
	action?: VariantActionFunc;
}
const varaints = new Map<number, Ivariant>([
	[
		0,
		{
			id: 0,
			name: "upsy-shown"
		}
	],
	[
		1,
		{
			id: 1,
			name: "upsy-hidden",
			action: options => {
				const { upsyRoot, updateIsChatInitDisabled } = options || {};
				if (!(upsyRoot && updateIsChatInitDisabled)) return;
				updateIsChatInitDisabled(true);
				upsyRoot.setAttribute(
					"style",
					"opacity: 0 !important; visibility: hidden !important; position: relative !important; z-index: -999999 !important;"
				);
			}
		}
	]
]);
export function generateVariantId() {
	//This will return either 0 || 1;
	return Math.floor(Math.random() * 2);
}

export function getAbTestVariantId() {
	const variantId = parseInt(abTestCookies.getVariantId());
	return Number.isFinite(variantId) ? variantId : generateVariantId();
}

export function getVariant() {
	return varaints.get(getAbTestVariantId());
}
