// This handler is not currently used - it was found in old code, and included in the case this "fc" would be used again.

const moveChat = () => {
	window.fcWidget?.on("widget:loaded", function () {
		window.fcWidget?.destroy();
	});
};

export const openChat = (closeWidget: () => void) => {
	window._initChat && window._initChat();
	window.fcWidget?.on("widget:loaded", function () {
		console.log("Widget Loaded");
		closeWidget();
		window.fcWidget?.open();
	});
};

export const initializeChat = () => {
	if (window.jQuery) {
		window.jQuery(document).ready(function () {
			moveChat();
		});
	} else {
		document.addEventListener("DOMContentLoaded", function () {
			moveChat();
		});
	}
};
