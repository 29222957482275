import { STORAGE_UPSY_CART_KEY, STORAGE_UPSY_PRODUCT_UPSELL_KEY } from "../constants";
import { getStorage, setStorage } from "../utils/storage";
import { IStorageUpsyProductUpsell } from "./event";

export interface ICartProduct {
	productId: string | null;
	quantity: string | null;
	name: string | null;
	unit_price: string | number | null;
	price_currency_code: string | null;
	upsell?: unknown;
	upsellType?: unknown;
}

function getContent(data: Element | null) {
	let content: string | null = "";
	if (data) {
		content = data.textContent ? data.textContent.trim() : "";
	}
	return content?.trim() || null;
}

export function getShoppingCart() {
	const upselledIds = getStorage(
		STORAGE_UPSY_PRODUCT_UPSELL_KEY
	) as IStorageUpsyProductUpsell[];

	const shoppingCart: ICartProduct[] = [];
	const cart = document.querySelector(".upseller_cart");
	if (cart) {
		const items = cart.getElementsByClassName("line_item");

		if (items && items.length) {
			for (let i = 0; i < items.length; i++) {
				const productId = items[i].querySelector(".product_id");
				const quantity = items[i].querySelector(".quantity");
				const name = items[i].querySelector(".name");
				const unit_price = items[i].querySelector(".unit_price");
				const price_currency_code =
					items[i].querySelector(".price_currency_code");

				const product: ICartProduct = {
					productId: getContent(productId),
					quantity: getContent(quantity),
					name: getContent(name),
					unit_price: getContent(unit_price),
					price_currency_code: getContent(price_currency_code)
				};

				if (upselledIds && upselledIds.length) {
					for (let k = 0; k < upselledIds.length; k++) {
						if (upselledIds[k].productId == product["productId"]) {
							product["upsell"] = upselledIds[k].productId;
							product["upsellType"] = upselledIds[k].context;
						}
					}
				}
				shoppingCart.push(product);
			}
		}
	}

	if (shoppingCart.length) {
		setStorage(STORAGE_UPSY_CART_KEY, shoppingCart);
	}

	return shoppingCart;
}

export function getOrder() {
	const upselledIds = getStorage(
		STORAGE_UPSY_PRODUCT_UPSELL_KEY
	) as IStorageUpsyProductUpsell[];

	// const shopifyOrder = getShopifyOrder();
	const orderByUpsyTag = getOrderByUpsyTag();
	let order = orderByUpsyTag.length ? orderByUpsyTag : getShopifyOrder();
	order = order.map(product => {
		if (upselledIds && upselledIds.length) {
			for (let k = 0; k < upselledIds.length; k++) {
				if (upselledIds[k].productId == product["productId"]) {
					product["upsell"] = upselledIds[k].productId;
					product["upsellType"] = upselledIds[k].context;
				}
			}
		}
		return product;
	});
	return order;
}

export function recheckShoppingCart(duration = 5000): Promise<ICartProduct[]> {
	return new Promise(function (resovle, reject) {
		let cart = getShoppingCart();

		if (cart.length === 0) {
			setTimeout(() => {
				try {
					cart = getShoppingCart();
					resovle(cart);
				} catch (e) {
					reject(e);
				}
			}, duration);
		}
	});
}

export function getAddToCartElements(selector: string) {
	let elements: Element[] = [];
	let nodeList = document.querySelectorAll(selector);
	if (nodeList.length) {
		elements = [...Array.from(nodeList)];
	}
	nodeList = document.querySelectorAll(".BuyForm .SubmitButton");
	if (nodeList && !elements.length) {
		elements = [...Array.from(nodeList)];
	}
	nodeList = document.querySelectorAll(".single_add_to_cart_button");
	if (nodeList && !elements.length) {
		elements = [...Array.from(nodeList)];
	}
	return elements;
}

export function getOrderByUpsyTag() {
	const order: ICartProduct[] = [];
	const items = document.querySelectorAll(".upseller_purchase_order .line_item").length
		? document.querySelectorAll(".upseller_purchase_order .line_item")
		: document.querySelectorAll(".upsy_order .line_item");
	if (items && items.length) {
		for (let i = 0; i < items.length; i++) {
			const productId = items[i].querySelector(".product_id");
			const quantity = items[i].querySelector(".quantity");
			const name = items[i].querySelector(".name");
			const unit_price = items[i].querySelector(".unit_price");
			const price_currency_code = items[i].querySelector(".price_currency_code");

			const product: ICartProduct = {
				productId: getContent(productId),
				quantity: getContent(quantity),
				name: getContent(name),
				unit_price: getContent(unit_price),
				price_currency_code: getContent(price_currency_code)
			};
			//On woo-commerce , productId: -1 refer the shipping on order line item collection not an actual product
			/*
			Here is an example
				{
					"productId": "-1",
					"quantity": "1",
					"name": "Shipping",
					"unit_price": "7.32",
					"price_currency_code": "EUR"
				}
			*/
			if (product.productId == "-1") {
				continue;
			}
			order.push(product);
		}
	}
	return order;
}

export function getShopifyOrder() {
	if (window.Shopify && window.Shopify.checkout) {
		const { line_items, currency } = window.Shopify.checkout;

		if (!line_items || !Array.isArray(line_items) || !currency) {
			return [];
		}
		const orders = line_items.map(({ price, quantity, product_id, title }) => {
			const product: ICartProduct = {
				productId: `${product_id}`,
				quantity: `${quantity}`,
				name: `${title}`,
				unit_price: `${price}`,
				price_currency_code: `${currency}`
			};
			return product;
		});

		return orders;
	}
	return [];
}
export function getLocalStorageCartItems(): ICartProduct[] {
	return getStorage(STORAGE_UPSY_CART_KEY) as ICartProduct[];
}
export function getWooCommerceOrderId(): string {
	const orderIdContainerElement = document.querySelector(
		".upseller_purchase_order .order_number"
	);
	if (orderIdContainerElement) {
		return getContent(orderIdContainerElement) || "";
	}
	return "";
}
function getShopifyOrderId(): string {
	if (!(window.Shopify && window.Shopify.checkout)) return "";
	return window.Shopify.checkout.order_id ? `${window.Shopify.checkout.order_id}` : "";
}
export function getOrderId(): string {
	return getWooCommerceOrderId() || getShopifyOrderId();
}
