type CurrencyLocaleMap = Record<string, string[] | string>;

// Mapping between currency code and locale code
// In future for supporting more currency/locale have to add them in this list with their corresponding currency and locale code
const currencyLocaleMap: CurrencyLocaleMap = {
	USD: "en-US",
	EUR: [
		"nl-NL", // Dutch
		"en-GB", // English
		"fi-FI", // Finnish
		"de-DE", // German
		"sv-SE" // Swedish
	],
	GBP: "en-GB",
	SEK: "sv-SE"
};

// Get local code by currency code and store language for formating the product price with correct format
export function getLocaleForPriceFormating(currencyCode: string, lang: string) {
	const locale = currencyLocaleMap[currencyCode.toUpperCase()];
	if (Array.isArray(locale)) {
		return locale.find(locale => locale.toUpperCase().includes(lang.toUpperCase()));
	}
	return locale;
}
