<style>
	.u-whitespace-break-spaces {
		white-space: break-spaces;
	}
</style>

<script lang="ts">
	import { getContext } from "svelte";
	import { storeConfig } from "../../stores/storeConfig";
	import { GlobalContextKeyEnum } from "../../types/context";
	import { IConfigObject, IFAQ } from "../../types/sdk";
	import { postFaqQuestionEvent } from "../../utils/event";
	import { locale } from "../../i18n/i18n";
	let upsyConfig: IConfigObject;
	$: upsyConfig = $storeConfig;
	const environment = getContext<string>(GlobalContextKeyEnum.ENVIRONMENT);
	let showFaqContent = false;
	export let faq: IFAQ;
	export let previewMode = false;
	// Faq localize(question, answer) section
	$: question = faq.locale?.[$locale]?.question || faq.question || "";
	$: answer = faq.locale?.[$locale]?.answer || faq.answer || "";

	function faqQuestionClickHandler() {
		showFaqContent = !showFaqContent;
		try {
			if (showFaqContent) {
				!previewMode &&
					void postFaqQuestionEvent(
						upsyConfig,
						environment,
						question as string
					);
			}
		} catch (error) {
			console.log(error);
		}
	}
</script>

<div
	on:click={faqQuestionClickHandler}
	class=" u-cursor-pointer u-shadow-faq-item u-select-none u-flex u-justify-between u-items-center u-mb-2 u-px-4 u-font-inter u-text-[14.5px] u-font-normal u-leading-5 u-text-black-secondary u-rounded-lg u-bg-white">
	<p class="u-py-4 u-word-wrap-break-word u-text-left">{@html question}</p>
	<p class="u-p-4 u-cursor-pointer">
		<svg
			width="10"
			height="6"
			viewBox="0 0 10 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1 1L5 5L9 1"
				stroke="#212122"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round" />
		</svg>
	</p>
</div>

<div
	class={`u-px-4 u-transition-transform u-origin-top u-bg-white u-rounded-lg u-bg-accent-light  u-border-1 u-border-accent u-border-solid ${
		showFaqContent ? " u-scale-y-100 u-h-auto u-mb-2  " : " u-h-0 u-scale-y-0"
	}`}>
	<div class="u-font-inter u-flex u-justify-between u-items-start u-py-5">
		<div
			class="u-whitespace-pre-wrap u-whitespace-break-spaces u-m-0 u-ml-2 u-p-0 u-text-[14.5px] u-text-left u-font-normal u-text-black-secondary u-leading-[1.4] u-word-wrap-break-word">
			{#if faq.type.toLocaleLowerCase() === "url"}
				<a
					class="u-word-wrap-break-word"
					href={answer}
					target="_blank"
					title={answer}>{answer}</a>
			{:else}
				{@html answer}
			{/if}
		</div>
		<p
			on:click={() => (showFaqContent = !showFaqContent)}
			class="u-justify-end u-flex u-items-start u-cursor-pointer u-px-3 u-pt-5 u-pb-6 -u-mt-4 -u-ml-1">
			<svg
				width="10"
				height="6"
				viewBox="0 0 10 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9 5L5 1L1 5"
					stroke="#212122"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round" />
			</svg>
		</p>
	</div>
</div>
