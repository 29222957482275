import { ImageUrlReplaceOptions } from "../types/sdk";

export const joinURL = (...args: string[]) => {
	return args
		.join("/")
		.replace(/[/]+/g, "/")
		.replace(/^(.+):\//, "$1://")
		.replace(/^file:/, "file:/")
		.replace(/\/(\?|&|#[^!])/g, "$1")
		.replace(/\?/g, "&")
		.replace("&", "?");
};

export const getCurrentUrlWithoutQueryParams = () => {
	return window.location.href.split("?")[0].replace(/^\/+|\/+$/g, "");
};

export function trimSlashes(str: string, fromStart = true, fromEnd = true): string {
	if (!str || typeof str !== "string") {
		return str;
	}

	let start = 0;
	let end = str.length;

	if (fromStart === true) {
		while (str[start] === "/") {
			start++;
		}
	}

	if (fromEnd === true) {
		while (str[end - 1] === "/" && end > 0) {
			end--;
		}
	}

	return str.slice(start, end);
}

const isString = (value: unknown): value is string => {
	return typeof value === "string";
};

// Function to replace the image URL based on an array of replaceOptions
export const replaceImageUrl = (
	url?: string,
	replaceOptionsArray?: ImageUrlReplaceOptions[]
): string => {
	// Check if url is falsy or not a string
	if (!url || !isString(url)) {
		return "";
	}

	if (!Array.isArray(replaceOptionsArray)) {
		return url;
	}
	// Decode the URL to handle any percent-encoded characters
	let decodedUrl: string;
	try {
		decodedUrl = decodeURI(url);
	} catch (error) {
		// If URL decoding fails, return the original URL
		console.error(`Invalid URL: ${url}`, error);
		return url;
	}

	// Iterate over each replace option in the array
	for (const replaceOptions of replaceOptionsArray) {
		const {
			enabled = false, // Assume disabled by default if not specified
			regex,
			replacePattern,
			caseSensitive = true, // Case-sensitive by default
			globalReplace = false // Replace only first match by default
		} = replaceOptions || {};

		// If the current options are disabled or invalid, skip to the next one
		if (!enabled || !isString(regex) || !isString(replacePattern)) {
			continue;
		}

		try {
			// Build the regex with optional flags for case sensitivity and global replacement
			const flags = `${!caseSensitive ? "i" : ""}${globalReplace ? "g" : ""}`;
			const regexPattern = new RegExp(regex, flags);

			// Perform the replacement on the decoded URL
			const newUrl: string = decodedUrl.replace(regexPattern, replacePattern);

			// If the URL has changed, return the newly modified URL
			if (newUrl !== decodedUrl) {
				// Encode the updated URL before returning it
				return encodeURI(newUrl);
			}
		} catch (error) {
			// Handle invalid regex or other unexpected errors
			console.error(`Invalid regex pattern: ${regex}`, error);
		}
	}

	// Return the original URL if no replacements were made
	return url;
};
