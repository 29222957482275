export default {
	greeting_lg: "Vad tänker du på",
	new: "ny",
	sale: "försäljning",
	chat_btn_text: "Chatta med oss",
	show_less: "Se mindre",
	see_all: "Se mer",
	submit: "Skicka in",
	feedback_textInBubble: "Var jag hjälpsam?",
	feedback_positiveFeedback: "Vad tyckte du om?",
	feedback_negativeFeedback: "Något att förbättra?",
	loading: "Läser in",
	no_product_found: "Tyvärr, inga produkter hittades!",
	categories: "Kategorier",
	new_arrivals: "Nyheter",
	daily_deals: "Fynda",
	similar_products: "Liknande Produkter",
	faqs: "Frågor",
	popular_categories: "Populära kategorier",
	newest_products: "Nya produkter",
	best_deals: "De bästa erbjudandena",
	others_also_purchased: "Andra köpte också",
	have_you_seen_these: "Har du sett dessa",
	any_questions: "Några frågor",
	frequently_asked_questions: "Vanliga frågor",
	thank_you: "Tack!",
	good_morning: "God morgon!",
	good_noon: "God dag!",
	good_afternoon: "God eftermiddag!",
	good_evening: "God kväll!",
	good_night: "God kväll!",
	no_categories_found: "Tyvärr, inga kategorier hittades!",
	browse_new_products: "Bläddra bland nya produkter",
	do_u_need_help: "Behöver du hjälp",
	sidebar_cta_text_for_daily_deals: "Se erbjudanden",
	sidebar_cta_text_for_up_sell: "Se alternativ",
	sidebar_cta_text_for_cross_sell: "Andra köpte",
	sidebar_cta_text_for_new_arrival: "Nyankomna",
	sidebar_cta_text_for_front_page: "Snabb shopping"
};
