export enum SwipeDirection {
	LEFT = "left",
	RIGHT = "right",
	TOP = "top",
	BOTTOM = "bottom"
}

export type ISwipeDirection = `${SwipeDirection}` | null;

export interface ISwipePayload {
	swipeDirection: ISwipeDirection;
}

export const swipeAction = (node: Node) => {
	let xDown = 0;
	let yDown = 0;
	let isSwiped = false;
	let swipeDirection: ISwipeDirection = null;

	function getTouches(evt: TouchEvent) {
		return evt.touches;
	}

	function handleTouchStart(evt: Event) {
		if (evt instanceof TouchEvent) {
			const firstTouch = getTouches(evt)[0];
			xDown = firstTouch.clientX;
			yDown = firstTouch.clientY;
		}
	}

	function handleTouchMove(evt: Event) {
		if (!xDown || !yDown) {
			return;
		}

		if (evt instanceof TouchEvent) {
			const xUp = evt.touches[0].clientX;
			const yUp = evt.touches[0].clientY;
			const xDiff = xDown - xUp;
			const yDiff = yDown - yUp;

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				/*most significant*/
				if (xDiff > 0) {
					/* left swipe */
					swipeDirection = SwipeDirection.LEFT;
					isSwiped = true;
				} else {
					/* right swipe */
					isSwiped = true;
					swipeDirection = SwipeDirection.RIGHT;
				}
			} else {
				if (yDiff > 0) {
					/* top swipe */
					swipeDirection = SwipeDirection.TOP;
					isSwiped = true;
				} else {
					/* bottom swipe */
					swipeDirection = SwipeDirection.BOTTOM;
					isSwiped = true;
				}
			}
			/* reset values */
			xDown = 0;
			yDown = 0;
		}
	}

	function handleTouchEnd() {
		if (isSwiped) {
			node.dispatchEvent(
				new CustomEvent<ISwipePayload>("swipe", { detail: { swipeDirection } })
			);
		}
		isSwiped = false;
	}
	if (node) {
		node.addEventListener("touchstart", handleTouchStart, false);
		node.addEventListener("touchmove", handleTouchMove, false);
		node.addEventListener("touchend", handleTouchEnd, false);
	}
	return {
		destroy() {
			node.removeEventListener("touchstart", handleTouchStart, false);
			node.removeEventListener("touchmove", handleTouchMove, false);
			node.removeEventListener("touchend", handleTouchEnd, false);
		}
	};
};
