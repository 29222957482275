const QUERY_KEYWORDS = {
	OR: "or",
	AND: "and",
	NOT: "not",
	LEFT_PARENTHESIS: "(",
	RIGHT_PARENTHESIS: ")"
};

const escapeRegex = (str: string) => {
	return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
};

const findMathcingParenthesis = (queryParts: string[], startingIndex = 0) => {
	let counter = 1;
	let index = startingIndex;
	for (const part of queryParts.slice(index)) {
		if (part === "(") {
			counter += 1;
		} else if (part === ")") {
			counter -= 1;
		}
		if (counter === 0) {
			break;
		}
		index += 1;
	}

	return index;
};

const UnexpectKeywordError = (keyword: string, descriptionString = "in the query") =>
	Error(`Encountered an unexpected character '${keyword}' ${descriptionString}`);

const keywords: Keyword[] = [
	{
		label: QUERY_KEYWORDS.OR,
		regexp: `(\\s+)${QUERY_KEYWORDS.OR}(?=(\\s+).+)`,
		func: (valueFunction, index, queryParts, prevResult) => {
			if (prevResult === undefined) {
				throw UnexpectKeywordError(QUERY_KEYWORDS.OR, "without a left hand side");
			}
			if (prevResult) {
				return {
					result: true,
					nextIndex: queryParts.length,
					queryParts
				};
			}
			const { result: rightResult } = parseUpsyQuery(
				valueFunction,
				index + 1,
				queryParts.join(" "),
				undefined,
				false
			);
			return {
				result: prevResult || rightResult,
				nextIndex: queryParts.length,
				queryParts
			};
		}
	},
	{
		label: QUERY_KEYWORDS.AND,
		regexp: `(\\s+)${QUERY_KEYWORDS.AND}(?=(\\s+).+)`,
		func: (valueFunction, index, queryParts, prevResult) => {
			if (prevResult === undefined) {
				throw UnexpectKeywordError(
					QUERY_KEYWORDS.AND,
					"without a left hand side"
				);
			}
			if (!prevResult) {
				return {
					result: false,
					nextIndex: queryParts.length,
					queryParts
				};
			}
			const { result: rightResult } = parseUpsyQuery(
				valueFunction,
				index + 1,
				queryParts.join(" "),
				undefined,
				false
			);
			return {
				result: prevResult && rightResult,
				nextIndex: queryParts.length,
				queryParts
			};
		}
	},
	{
		label: QUERY_KEYWORDS.LEFT_PARENTHESIS,
		regexp: `(^|\\s+)${escapeRegex(QUERY_KEYWORDS.LEFT_PARENTHESIS)}($|\\s*)`,
		func: (valueFunction, index, queryParts) => {
			const matchingParenthesesIndex = findMathcingParenthesis(
				queryParts,
				index + 1
			);
			const subQuery = queryParts.slice(index + 1, matchingParenthesesIndex);
			const { result: subResult } = parseUpsyQuery(
				valueFunction,
				0,
				subQuery.join(" ")
			);
			return {
				result: subResult,
				nextIndex: matchingParenthesesIndex + 1,
				queryParts
			};
		}
	},
	{
		label: QUERY_KEYWORDS.RIGHT_PARENTHESIS,
		regexp: `(^|\\s*)${escapeRegex(QUERY_KEYWORDS.RIGHT_PARENTHESIS)}($|\\s+)`,
		func: (valueFunction, index, query) => {
			// The right parenthesis should never be encountered for parsing, because left parenthesis should skip it
			throw UnexpectKeywordError(QUERY_KEYWORDS.RIGHT_PARENTHESIS);
		}
	},
	{
		label: QUERY_KEYWORDS.NOT,
		regexp: `(^|\\s+)${QUERY_KEYWORDS.NOT}`,
		func: (valueFunction, index, queryParts) => {
			if (index > queryParts.length) {
				throw UnexpectKeywordError(
					QUERY_KEYWORDS.NOT,
					"without a right hand side"
				);
			}
			const { result: rightResult, nextIndex } = parseUpsyQuery(
				valueFunction,
				index + 1,
				queryParts.join(" "),
				undefined,
				false
			);
			return {
				result: !rightResult,
				nextIndex: nextIndex,
				queryParts
			};
		}
	}
];

const getQueryParts = (query: string) => {
	const queryRegexp = new RegExp(
		`${keywords.map(x => `(${x.regexp})`).join("|")}`,
		"i"
	);

	return query
		.split(queryRegexp)
		.map(x => x && x.trim())
		.filter(x => x);
};

const parseUpsyQuery = (
	valueFunction: ValueFunction,
	index: number,
	query: string,
	prevResult?: boolean,
	continueParsing = true
): QueryParseResult => {
	const queryParts = getQueryParts(query);

	const part = queryParts[index];
	const keyword = keywords.filter(
		keyObject => keyObject.label.toLowerCase() === part.toLowerCase()
	)[0];
	const { result, nextIndex } = keyword
		? keyword.func(valueFunction, index, queryParts, prevResult)
		: {
				result: valueFunction(part),
				nextIndex: index + 1
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  };

	if (continueParsing && queryParts.length > nextIndex) {
		const nextResult = parseUpsyQuery(valueFunction, nextIndex, query, result);
		return nextResult;
	}

	return {
		result,
		nextIndex,
		queryParts
	};
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
(window as any).parseUpsyQuery = parseUpsyQuery;

export { parseUpsyQuery };
