<script lang="ts">
	import variantType, { variantEnum } from "../../types/variant";
	export let variant: variantType;
	export let label: string;
	let classZ = "";
	export { classZ as class };
</script>

<span
	class={`${
		variant === variantEnum.MINIFIED
			? "u-text-[8px] sm:u-text-[9px] sm:u-leading-4 sm:u-font-medium u-font-semibold u-leading-3"
			: "u-text-xs u-font-medium u-leading-4"
	} u-uppercase u-text-white u-rounded-default u-px-2 u-py-[2px] ${classZ}`}>
	{label}
</span>
