import { get } from "svelte/store";
import { IConfigObject } from "../../types/sdk";
import { sendAbTestInitEvent, sendAbTestPurchaseEvent } from "./event";
import { getAbTestVariantId, getVariant } from "./variant";
import { storeConfig } from "../../stores/storeConfig";
import { getPageType, pageTypeEnum } from "../pageType";
import { getUpsySessionId } from "../session";
import { getAbTestEventURL } from "../getUpsyUrls";
import { abTestCookies } from "./cookieManager";
import { updateIsChatInitDisabled } from "../../stores/chatHandler";

export function configureAbTest(upsyConfig: IConfigObject, upsyRoot: HTMLElement) {
	try {
		const { activeExperimentId } = upsyConfig;

		if (!activeExperimentId) {
			return false;
		}
		const variantId = getAbTestVariantId();
		if (activeExperimentId !== abTestCookies.getExperimentId()) {
			abTestCookies.clearAll();
		}

		const variant = getVariant();
		if (!variant) {
			console.warn("Sorry no variant found for variant id:", variantId);
			return false;
		}
		const { id, name, action } = variant;

		// Add relevant a/b test cookies
		abTestCookies.setAll(`${id}`, name, activeExperimentId);

		if (action && typeof action === "function") {
			action({ upsyRoot, updateIsChatInitDisabled });
		}
		return true;
	} catch (e) {
		console.error("Scope: Configure ab test ", e);
		return false;
	}
}

export function checkIsAbTestEnabled(upsyConfig: IConfigObject) {
	const { activeExperimentId, experiments } = upsyConfig;
	if (!activeExperimentId) return false;
	if (!Array.isArray(experiments)) return false;
	return experiments
		.filter(experiment => experiment.experimentId === activeExperimentId)
		.some(experiment => {
			if (!experiment.endTs) return true;
			return (
				new Date().getTime() <
				new Date(parseInt(experiment.endTs as unknown as string)).getTime()
			);
		});
}

export function sendAbTestEvents(baseUrl: string) {
	const configs = get(storeConfig);
	const { tenantId, activeExperimentId: experimentId } = configs;
	if (!(tenantId && experimentId)) return;
	//Get current page type
	const pageType = getPageType(configs);
	// getAbTestVariantId function call return either 0 or 1, 0 = upsyshown , 1 = upsyhidden
	const isUpsyHidden = getAbTestVariantId() ? true : false;
	//Get current upsy session id
	const sessionId = getUpsySessionId();
	const isThankYouPage = pageType === pageTypeEnum.THANKYOU;
	try {
		const url = getAbTestEventURL(baseUrl, tenantId, experimentId);
		//On every page load/reload/refresh init event will be send
		sendAbTestInitEvent(url, { tenantId, experimentId, isUpsyHidden, sessionId });
		// On thank you page purchase event will be send
		if (isThankYouPage) {
			const isAttachPurchaseProductsInfo =
				configs._abTest_isAttachPurchaseProductsInfo;
			sendAbTestPurchaseEvent(
				url,
				{
					tenantId,
					experimentId,
					isUpsyHidden,
					sessionId
				},
				isAttachPurchaseProductsInfo
			);
		}
	} catch (e) {
		console.warn(e);
	}
}
