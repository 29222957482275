<script lang="ts">
	import "./main.css";
	import { activeWidgetScreen } from "./stores/activeWidgetScreen";
	import { activeWidgetScreenEnum } from "./types/activeWidgetScreen";
	import GreetingLarge from "./components/shared/GreetingLarge.svelte";
	import GreetingMini from "./components/shared/GreetingMini.svelte";
	import WidgetMinified from "./components/widgetMinified/WidgetMinified.svelte";
	import WidgetExpanded from "./components/widgetExpanded/WidgetExpanded.svelte";
	import Feedback from "./components/feedback/Feedback.svelte";
	import { activePage } from "./stores/activePage";
	import { Unsubscriber } from "svelte/store";
	import { onDestroy, onMount, setContext } from "svelte";
	import {
		getPageType,
		getWidgetScreenByPageType,
		pageTypeEnum
	} from "./utils/pageType";
	import { GlobalContextKeyEnum, UpsyContextEnum } from "./types/context";
	import { getUpsyRecommendationContext } from "./utils/getUpsyContext";
	import { registerEventToAll } from "./utils/domEvent";
	import { minifiedWidgetLastUpsyContext, upsyContext } from "./stores/upsyContext";
	import { getAddToCartElements } from "./utils/cart";
	import { postClickEvent, postDisplayScreenEvent } from "./utils/event";
	import {
		ifUpsyHaveToLiftThenLiftUpsy,
		ifWidgetToLeftThenTransformPositions
	} from "./utils/widgetPosition";
	import { chatHandlers } from "./utils/chatHandlers";

	import { eventProductPayload } from "./stores/ProductEvent";
	import { getUpsyContexualProducts } from "./utils/productFetch";
	import { isProductsLoadingSpinner } from "./stores/loadingSpinner";
	import { upsyContexualProducts } from "./stores/products";
	import { upsyProductsContextList } from "./config/var";
	import { storeConfig } from "./stores/storeConfig";
	import { IConfigObject, IGreetings } from "./types/sdk";
	import { getTextInBubble } from "./utils/getUpsyText";
	import { cookies } from "./utils/cookieManager";
	import { parseUpsyQuery } from "./utils/upsyQuery/upsyQuery";
	import { getCurrentUrlWithoutQueryParams } from "./utils/url";
	import { isChatInitDisabled } from "./stores/chatHandler";
	import { useMinimizeMode } from "./stores/minimizeMode";

	export let previewMode = false;
	let config: IConfigObject;
	let greetings: IGreetings;
	$: config = $storeConfig;
	$: greetings = config.greetings || {};
	const upsyClosedCookieName = "_upsyClosedByUser";
	export let environment: string;
	export let positionType: "absolute" | "fixed" | "relative" = "fixed";

	export let targetElement: HTMLElement;

	$: targetElement.style.setProperty("--accent-color", config.buttonColor || null);
	$: targetElement.style.setProperty(
		"--accent-color-light",
		config.accentColorLight || null
	);

	setContext(GlobalContextKeyEnum.ENVIRONMENT, environment);

	$: chatType = config?.chatOptions?.type;
	$: chatHandler = chatType ? chatHandlers[chatType] : null;
	$: chatEnabled = config?.chatOptions?.enabled;
	$: if (chatHandler && chatEnabled && !$isChatInitDisabled) {
		try {
			chatHandler.initializeChat();
		} catch (e) {
			console.error(e);
		}
	}
	// Use widget minimize mode logic hook
	const { isMinimizedMode, updateMinimizeModeTo } = useMinimizeMode(
		$storeConfig.minimizedMode
	);
	// Utility function for reuse to go to minimized widget
	function toMinimizedWidget() {
		activeWidgetScreen.update(() => activeWidgetScreenEnum.WIDGETMINIFIED);
		if ($upsyContext === UpsyContextEnum.FAQ) {
			$upsyContext = UpsyContextEnum.CATEGORY;
		}
	}
	// Utility function for reuse to go to maximized/expanded widget
	function toExpandedWidget() {
		activeWidgetScreen.update(() => activeWidgetScreenEnum.WIDGETEXPANDED);
		try {
			!previewMode &&
				void postDisplayScreenEvent(
					config,
					environment,
					$upsyContext,
					$eventProductPayload
				);
		} catch (error) {
			console.log(error);
		}
	}
	//Utility function for reuse to close widget
	function closeHandler() {
		// Disable skip minimizedMode, when config minimizedMode set to true - because user close upsy by close button click
		updateMinimizeModeTo(true);
		activeWidgetScreen.update(() => activeWidgetScreenEnum.GREETINGSM);
		cookies.setCookie(upsyClosedCookieName, "true", 0.5);
		isUpsyClosedByUser = true;
	}

	function onExpandClickHandler() {
		// Skip minimizedMode even if config minimizedMode set to true - because user interact with upsy
		updateMinimizeModeTo(false);
		// Force upsycloseByUser to false because when somebody go to the expanded widget that means he/she intended to use and interact with upsy
		cookies.setCookie(upsyClosedCookieName, "false", 0.5);
		isUpsyClosedByUser = false;
		minifiedWidgetLastUpsyContext.set($upsyContext);
		toExpandedWidget();
		sendClickHandlerEvent();
	}

	function onChatClickHandler() {
		activeWidgetScreen.set(activeWidgetScreenEnum.GREETINGSM);
	}

	function chatNavigateHandler() {
		// Skip minimizedMode even if config minimizedMode set to true - because user interact with upsy
		updateMinimizeModeTo(false);
		cookies.setCookie(upsyClosedCookieName, "false", 0.5);
		isUpsyClosedByUser = false;
		if ($upsyContext === UpsyContextEnum.CROSS_SELL) {
			minifiedWidgetLastUpsyContext.set(UpsyContextEnum.CROSS_SELL);
		} else if ($upsyContext === UpsyContextEnum.UP_SELL) {
			minifiedWidgetLastUpsyContext.set(UpsyContextEnum.UP_SELL);
		}
		activeWidgetScreen.set(activeWidgetScreenEnum.WIDGETEXPANDED);
		if ($upsyContext !== UpsyContextEnum.FAQ) {
			upsyContext.set(UpsyContextEnum.FAQ);
			try {
				!previewMode && sendClickHandlerEvent();
				!previewMode &&
					void postDisplayScreenEvent(
						config,
						environment,
						$upsyContext,
						$eventProductPayload
					);
			} catch (error) {
				console.log(error);
			}
		}
	}

	function onMinimizeHandler() {
		let prevContextOnMinifiedWidget = $minifiedWidgetLastUpsyContext;
		minifiedWidgetLastUpsyContext.set(null);
		const pageType = getPageType(config);
		if (
			(pageType == pageTypeEnum.CART || pageType == pageTypeEnum.CHECKOUT) &&
			$upsyContext == UpsyContextEnum.FAQ
		) {
			activeWidgetScreen.set(activeWidgetScreenEnum.ANYQUESTION);
		} else {
			//Upsy context update logic
			if (
				prevContextOnMinifiedWidget &&
				prevContextOnMinifiedWidget == UpsyContextEnum.CROSS_SELL
			) {
				upsyContext.set(UpsyContextEnum.CROSS_SELL);
			} else if (
				prevContextOnMinifiedWidget &&
				prevContextOnMinifiedWidget == UpsyContextEnum.UP_SELL
			) {
				upsyContext.set(UpsyContextEnum.UP_SELL);
			}
			//Change active screen to minified widget
			toMinimizedWidget();
		}

		sendClickHandlerEvent();
		// Skip minimizedMode even if config minimizedMode set to true - because user interact with upsy
		updateMinimizeModeTo(false);
		// Make upsy close by user to false
		cookies.setCookie(upsyClosedCookieName, "false", 0.5);
		isUpsyClosedByUser = false;
	}

	function sendClickHandlerEvent() {
		try {
			//Post click event only send once;
			!previewMode && void postClickEvent(config, environment);
		} catch (error) {
			console.log(error);
		}
	}

	const anyQuestionWidgetClickHandler = onExpandClickHandler;
	const thankYouWidgetClickHandler = onMinimizeHandler;
	const welcomeWidgetClickHandler = onMinimizeHandler;
	let isUpsyClosedByUser: boolean;
	$: isUpsyClosedByUser =
		cookies.getCookie(upsyClosedCookieName) === "true" ? true : false;
	//A utility function to check if the widget should be moved to the left. If it is to the left, it will transform the position props to move the widget to the left.
	$: defaultPositionProps = ifWidgetToLeftThenTransformPositions(config.widgetToLeft, {
		position: positionType,
		zIndex: $storeConfig.zIndex || 99999,
		bottom: `15px`,
		right: `0px`
	});

	// Observe screen width
	$: outerWidth = window.outerWidth;

	/**
	 *If the configuration have liftUpsy/liftUpsyDesktop value then lift upsy up base on the value.
	 * liftUpsy is used for small screen ( screen default width equal or less 740px based on Upsy 2.0)
	 * liftUpsyDesktop used for bigger screen (screen default width bigger than 740px)
	 * smallScreenWidthThreshold are conifigurable via frontend config
	 */
	$: smallScreenWidthThreshold =
		parseInt(`${config.smallScreenWidthThreshold as number}`) || 740;
	$: positionProps = ifUpsyHaveToLiftThenLiftUpsy(
		config.liftUpsy,
		config.liftUpsyDesktop,
		outerWidth,
		defaultPositionProps,
		smallScreenWidthThreshold
	);

	let activePageStoreUnSubscriber: Unsubscriber;
	let removeAllAddToCartEvents: () => void;

	$: isMobileDevice =
		window.matchMedia("(max-width: 639px) and (orientation: portrait)").matches ||
		window.matchMedia("(max-height: 639px) and (orientation: landscape)").matches;

	// Determine whether Upsy should close or not
	$: isUpsyOnCloseState =
		isUpsyClosedByUser && $upsyContext != UpsyContextEnum.CROSS_SELL;

	function handleOrientationChange() {
		isMobileDevice =
			window.matchMedia("(max-width: 639px) and (orientation: portrait)").matches ||
			window.matchMedia("(max-height: 639px) and (orientation: landscape)").matches;
	}

	onMount(() => {
		window.addEventListener("orientationchange", handleOrientationChange);
		activePageStoreUnSubscriber = activePage.subscribe(value => {
			const pageType = getPageType(config);
			$activeWidgetScreen = getWidgetScreenByPageType(
				value?.pageType ? value.pageType : pageType,
				$isMinimizedMode
			);
			upsyContext.update(() =>
				/*
				 * Exception case (getUpsyRecommendationContext): When new arrival tab is disabled
				 * and user on category page recommendation context will be category, for showing the category list
				 */
				getUpsyRecommendationContext(pageType, $storeConfig)
			);
		});

		upsyContext.subscribe(context => {
			if (upsyProductsContextList.indexOf(context) > -1) {
				void (async () => {
					try {
						$isProductsLoadingSpinner = true;
						$upsyContexualProducts = [];
						const products = await getUpsyContexualProducts(
							config,
							environment,
							context
						);
						$upsyContexualProducts = products;

						/*
						 * Products popup (minified widget with products) will automatically open only if upsy is not in minimize mode or product context is cross-sell although upsy is in minimize mode
						 */
						if (
							$activeWidgetScreen === activeWidgetScreenEnum.GREETINGSM &&
							products.length &&
							(!$isMinimizedMode || context === UpsyContextEnum.CROSS_SELL)
						) {
							$activeWidgetScreen = activeWidgetScreenEnum.WIDGETMINIFIED;
						}
					} catch (e) {
						console.log(e);
					} finally {
						$isProductsLoadingSpinner = false;
					}
				})();
			}
		});

		const addToCartElements = getAddToCartElements(config.addToCartSelector);

		const { addToCartOptions: { addIsValidQuery } = {} } = config;

		const addIsValid = () => {
			const valueFunction = (selector: string) => {
				const element = document.querySelector(selector);
				return !!element;
			};

			const valid = addIsValidQuery
				? parseUpsyQuery(valueFunction, 0, addIsValidQuery).result
				: true;

			return valid;
		};

		const onAddToCart = () => {
			if (!addIsValid()) {
				return;
			}
			const previousContext = $upsyContext;
			$upsyContext = UpsyContextEnum.CROSS_SELL;
			activeWidgetScreen.set(activeWidgetScreenEnum.GREETINGSM);
			if (previousContext === $upsyContext) {
				setTimeout(toMinimizedWidget, 150);
			}
			cookies.setCookie(getCurrentUrlWithoutQueryParams(), "1", 30 / 86400);
		};

		removeAllAddToCartEvents = registerEventToAll<void>(
			"click",
			addToCartElements,
			onAddToCart,
			$storeConfig.addToCartListenerCaptureMode === true
		);
	});

	onDestroy(() => {
		activePageStoreUnSubscriber && activePageStoreUnSubscriber();
		removeAllAddToCartEvents && removeAllAddToCartEvents();
		window.removeEventListener("orientationchange", handleOrientationChange);
	});
</script>

<!-- Bind window outerWidth for observing screen width -->
<svelte:window bind:outerWidth />
<!-- For active widget screen anyquestion (checkout page/cart page) upsy popup will be always shown even if upsy is close by the user -->
{#if $activeWidgetScreen == activeWidgetScreenEnum.ANYQUESTION}
	<GreetingLarge
		on:chatNavigate={chatNavigateHandler}
		welcomeText={$getTextInBubble($activeWidgetScreen, greetings)}
		{positionProps}
		on:click={anyQuestionWidgetClickHandler} />
{:else if isUpsyOnCloseState}
	<GreetingMini
		asStandAloneComponent={true}
		on:chatNavigate={chatNavigateHandler}
		on:click={welcomeWidgetClickHandler}
		on:minimize={onMinimizeHandler}
		on:close={closeHandler}
		{positionProps} />
{:else if $activeWidgetScreen === activeWidgetScreenEnum.GREETINGLG}
	<GreetingLarge
		on:chatNavigate={chatNavigateHandler}
		welcomeText={$getTextInBubble($activeWidgetScreen, greetings)}
		on:click={welcomeWidgetClickHandler}
		{positionProps} />
{:else if $activeWidgetScreen === activeWidgetScreenEnum.GREETINGSM}
	<GreetingMini
		asStandAloneComponent={true}
		on:chatNavigate={chatNavigateHandler}
		on:click={welcomeWidgetClickHandler}
		on:minimize={onMinimizeHandler}
		on:close={closeHandler}
		{positionProps} />
{:else if $activeWidgetScreen === activeWidgetScreenEnum.WIDGETMINIFIED}
	<WidgetMinified
		on:chatNavigate={chatNavigateHandler}
		{previewMode}
		{positionProps}
		on:expand={onExpandClickHandler}
		on:close={closeHandler} />
{:else if $activeWidgetScreen === activeWidgetScreenEnum.WIDGETEXPANDED}
	<WidgetExpanded
		on:chatNavigate={chatNavigateHandler}
		{previewMode}
		{positionProps}
		on:openChat={onChatClickHandler}
		on:minimize={onMinimizeHandler}
		on:close={closeHandler} />
{:else if $activeWidgetScreen === activeWidgetScreenEnum.FEEDBACK}
	<Feedback
		{previewMode}
		{positionProps}
		textInBubble={$getTextInBubble($activeWidgetScreen, greetings)} />
{:else if $activeWidgetScreen === activeWidgetScreenEnum.THANKYOU}
	<GreetingLarge
		on:chatNavigate={chatNavigateHandler}
		welcomeText={$getTextInBubble($activeWidgetScreen, greetings)}
		{positionProps}
		on:click={thankYouWidgetClickHandler} />
{:else}
	<GreetingLarge
		on:chatNavigate={chatNavigateHandler}
		welcomeText={$getTextInBubble($activeWidgetScreen, greetings)}
		on:click={welcomeWidgetClickHandler}
		{positionProps} />
{/if}
