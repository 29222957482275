<script lang="ts">
	export let height = 24;
	export let width = 24;
</script>

<span
	class=" u-h-6 u-w-6 u-overflow-hidden mobile-and-table-device:u-bg-accent mobile-and-table-device:u-text-white mobile-and-table-device:u-rounded-default u-cursor-pointer mobile-and-table-device u-absolute u-top-[12px] u-right-[-11px]"
	on:click>
	<svg
		{width}
		{height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.5633 7.2002L7.14844 16.8002"
			class="mobile-and-table-device:u-stroke-white u-stroke-[#393939]"
			stroke-width="2.09848"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7.14844 7.2002L16.5633 16.8002"
			class="mobile-and-table-device:u-stroke-white u-stroke-[#393939]"
			stroke-width="2.09848"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</span>
