export default {
	greeting_lg: "What´s on your mind",
	new: "New",
	sale: "Sale",
	chat_btn_text: "Click here to chat with us",
	show_less: "Show less",
	see_all: " See all",
	submit: "submit",
	feedback_textInBubble: "Did I manage to help you with your purchase?",
	feedback_positiveFeedback: "Thank you! What did you like?",
	feedback_negativeFeedback: "Thank you! What can we improve?",
	loading: "loading",
	no_product_found: "Sorry, no products were found",
	categories: "Categories",
	new_arrivals: "New arrivals",
	daily_deals: "Daily deals",
	similar_products: "Similar products",
	faqs: "FAQs",
	popular_categories: "Popular categories",
	newest_products: "Newest products",
	best_deals: "Best deals",
	others_also_purchased: "Others also purchased",
	have_you_seen_these: "Have you seen these",
	any_questions: "Any questions",
	frequently_asked_questions: "Frequently asked questions",
	thank_you: "Thank you",
	good_morning: "Good Morning",
	good_noon: "Good Noon",
	good_afternoon: "Good Afternoon",
	good_evening: "Good Evening",
	good_night: "Good Night",
	no_categories_found: "Sorry! No categories found",
	browse_new_products: "Browse new products",
	do_u_need_help: "Do you need help",
	sidebar_cta_text_for_daily_deals: "See deals",
	sidebar_cta_text_for_up_sell: "See options",
	sidebar_cta_text_for_cross_sell: "Others bought",
	sidebar_cta_text_for_new_arrival: "New arrivals",
	sidebar_cta_text_for_front_page: "Fast shopping"
};
