import { IZendeskClassic } from "../../types/externalLibraries";

const moveChat = () => {
	if (!window.zE) {
		setTimeout(moveChat, 200);
		return;
	}
	const zE = window.zE as IZendeskClassic;
	zE("webWidget", "hide");
};

export const openChat = (closeWidget: () => void) => {
	if (!window.zE) {
		setTimeout(openChat.bind(closeWidget), 200);
		return;
	}
	const zE = window.zE as IZendeskClassic;
	zE("webWidget", "open");
	zE("webWidget", "show");
	closeWidget();
};

export const initializeChat = () => {
	moveChat();
};
