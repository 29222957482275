<script lang="ts">
	import { storeConfig } from "../../stores/storeConfig";
	$: bgColor = $storeConfig.buttonColor || "#189DEB";
	$: accentColorLight = $storeConfig.accentColorLight || "#ffffff";
	export let height = 24;
	export let width = 24;
</script>

<span
	class=" u-h-6 u-w-6 u-overflow-hidden mobile-and-table-device:u-bg-accent mobile-and-table-device:u-text-white mobile-and-table-device:u-rounded-default u-cursor-pointer mobile-and-table-device u-absolute u-bottom-[169px] sm:u-top-[16px] u-right-0"
	on:click>
	<svg
		{width}
		{height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M24 20C24 22.2091 22.2091 24 20 24L4 24C1.79086 24 1.56562e-07 22.2091 3.49691e-07 20L1.74846e-06 4C1.94158e-06 1.79086 1.79086 -1.94158e-06 4 -1.74846e-06L20 -3.49691e-07C22.2091 -1.56562e-07 24 1.79086 24 4L24 20Z"
			fill={`${bgColor}`} />
		<path
			d="M12.7526 7.13295L17.1526 11.533C17.2193 11.5996 17.2666 11.6718 17.2946 11.7496C17.3222 11.8274 17.3359 11.9107 17.3359 11.9996C17.3359 12.0885 17.3222 12.1718 17.2946 12.2496C17.2666 12.3274 17.2193 12.3996 17.1526 12.4663L12.7526 16.8663C12.6304 16.9885 12.4777 17.0525 12.2946 17.0583C12.111 17.0636 11.9526 16.9996 11.8193 16.8663C11.6859 16.7441 11.6164 16.5914 11.6106 16.4083C11.6053 16.2247 11.6693 16.0663 11.8026 15.933L15.0693 12.6663L7.61927 12.6663C7.43038 12.6663 7.27194 12.6025 7.14394 12.475C7.01638 12.347 6.9526 12.1885 6.9526 11.9996C6.9526 11.8107 7.01638 11.6525 7.14394 11.525C7.27194 11.397 7.43038 11.333 7.61927 11.333L15.0693 11.333L11.8026 8.06629C11.6804 7.94406 11.6164 7.78851 11.6106 7.59962C11.6053 7.41073 11.6693 7.25518 11.8026 7.13295C11.9248 6.99962 12.0804 6.93295 12.2693 6.93295C12.4582 6.93295 12.6193 6.99962 12.7526 7.13295Z"
			fill={`${accentColorLight}`} />
	</svg>
</span>
