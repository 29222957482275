<script lang="ts">
	import { chatHandlers } from "../../utils/chatHandlers";
	import { createEventDispatcher } from "svelte";
	import { t } from "../../i18n/i18n";
	import { storeConfig } from "../../stores/storeConfig";
	import { IChatHandler, IConfigObject } from "../../types/sdk";

	const dispatch = createEventDispatcher();

	let config: IConfigObject;
	let chatHandler: IChatHandler | null;

	$: config = $storeConfig;
	$: chatType = config?.chatOptions?.type;
	$: chatHandler = chatType ? chatHandlers[chatType] : null;
	$: chatEnabled = config?.chatOptions?.enabled;
</script>

{#if chatHandler && chatEnabled}
	<div
		on:click={() => {
			chatHandler && chatHandler.openChat(() => dispatch("openChat"));
		}}
		class="u-relative u-bg-accent u-text-white u-m-5 u-mt-3 u-mb-1 u-cursor-pointer u-shadow-upsy u-select-none u-flex u-gap-2 u-items-center u-justify-between u-px-4 u-font-inter u-text-base u-font-normal u-leading-5 u-text-black-secondary u-bg-white u-rounded-full">
		<div
			class="u-shadow-[0_4px_10px_2px_rgba(0,255,56,0.5)] u-aspect-square u-bg-[rgb(0,255,56)] u-h-3 u-w-3 u-rounded-full" />

		<div class="u-flex u-justify-center u-items-center u-mx-auto u-gap-2">
			<svg
				height="16"
				width="16"
				preserveAspectRatio="none"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M15.9758 0H0.00798791L0 16L3.19517 12.8H15.9758V0ZM3.19517 5.6H12.7807V7.2H3.19517V5.6ZM9.5855 9.6H3.19517V8H9.5855V9.6ZM12.7807 4.8H3.19517V3.2H12.7807V4.8Z"
					fill="white" />
			</svg>
			<p
				class="u-flex-1 u-flex u-items-center u-text-white u-mr-auto u-h-[44px] u-leading-[120%] ">
				<span class="u-line-clamp-2 u-py-1">
					{$t("chat_btn_text")}
				</span>
			</p>
		</div>
	</div>
{/if}
