export function getCategoryId() {
	const upsyCategoryId = document.querySelector(".upseller_category")?.textContent;
	const upsyCategoryId2 = document.querySelector(
		".upseller_product .category"
	)?.textContent;
	const categoryId = upsyCategoryId || upsyCategoryId2 || "";
	return categoryId.trim();
}

export function getFormattedCategory(category: string | undefined | null): string | void {
	if (typeof category === "string") {
		const categoryList = category.split("/");
		const lastCategoryItem = categoryList.length - 1;
		return categoryList[lastCategoryItem].trim();
	}
}
