import { getEnvironmentEndpoints } from "../utils/getEnvironmentEndpoints";
import { getStaticJSURL } from "../utils/getUpsyUrls";

export const loadUpsyBranch = async (upsyBranch: string, customerId: string) => {
	const { staticResourceURL } = getEnvironmentEndpoints(upsyBranch);
	const url = getStaticJSURL(staticResourceURL);

	const scriptPromise = new Promise<void>(resolve => {
		const upsyScript = document.createElement("script");
		upsyScript.src = url;
		upsyScript.onload = () => resolve();
		document.body.appendChild(upsyScript);
	});
	await scriptPromise;
	void window.upsy_sdk.init(customerId, upsyBranch);
};
