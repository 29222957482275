import en from "./language/english";
import fi from "./language/finish";
import sv from "./language/swedish";
import de from "./language/german";
import nl from "./language/dutch";
import ee from "./language/estonian"; // Using ee as estonian language code as per https://ee.tattootukku.com/

export type Itranslation = {
	[name: string]: {
		[name: string]: string;
	};
};

const translation: Itranslation = { en, fi, sv, de, nl, ee };

export default translation;
