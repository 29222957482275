import { ICookieManager, cookies } from "../cookieManager";
export interface IAbTestCookieKeys {
	id: string;
	name: string;
	experimentId: string;
}
interface IAbTestCookieManager {
	getVariantId: () => void;
	setVariantId: (value: string, days?: number) => void;
	deleteVariantId: () => void;
	getVariantName: () => void;
	setVariantName: (value: string, days?: number) => void;
	deleteVariantName: () => void;
	getExperimentId: () => void;
	setExperimentId: (value: string, days?: number) => void;
	deleteExperimentId: () => void;
	setAll: (variantId: string, variantName: string, experimentId: string) => void;
	clearAll: () => void;
}
class AbTestCookieManager implements IAbTestCookieManager {
	private static instance: AbTestCookieManager;
	private cookies: ICookieManager;
	private expiryDays = 7;
	private prefix = "_upsy_ab_";
	private cookieKey: IAbTestCookieKeys = {
		id: `${this.prefix}variant_id`,
		name: `${this.prefix}variant_name`,
		experimentId: `${this.prefix}experiment_id`
	};
	private constructor(cookies: ICookieManager) {
		this.cookies = cookies;
	}
	public static getInstance(): AbTestCookieManager {
		if (!AbTestCookieManager.instance) {
			AbTestCookieManager.instance = new AbTestCookieManager(cookies);
		}
		return AbTestCookieManager.instance;
	}

	getVariantId() {
		const id = this.cookies.getCookie(this.cookieKey.id);
		if (id) this.setVariantId(id);
		return id;
	}
	setVariantId(id: string, expiryDays = this.expiryDays) {
		this.cookies.setCookie(this.cookieKey.id, id, expiryDays);
	}
	deleteVariantId() {
		this.cookies.deleteCookie(this.cookieKey.id);
	}
	getVariantName() {
		const name = this.cookies.getCookie(this.cookieKey.name);
		if (name) this.setVariantName(name);
		return name;
	}
	setVariantName(name: string, expiryDays = this.expiryDays) {
		this.cookies.setCookie(this.cookieKey.name, name, expiryDays);
	}
	deleteVariantName() {
		this.cookies.deleteCookie(this.cookieKey.name);
	}
	getExperimentId() {
		const expId = this.cookies.getCookie(this.cookieKey.experimentId);
		if (expId) this.setExperimentId(expId);
		return expId;
	}
	setExperimentId(id: string, expiryDays = this.expiryDays) {
		this.cookies.setCookie(this.cookieKey.experimentId, id, expiryDays);
	}
	deleteExperimentId() {
		this.cookies.deleteCookie(this.cookieKey.experimentId);
	}

	setAll(variantId: string, variantName: string, experimentId: string) {
		this.setVariantId(variantId);
		this.setVariantName(variantName);
		this.setExperimentId(experimentId);
	}
	clearAll() {
		this.deleteVariantId();
		this.deleteVariantName();
		this.deleteExperimentId();
	}
}
export const abTestCookies = AbTestCookieManager.getInstance();
