export const removeWidget = (parent: ParentNode = document) => {
	const upsyWidgets = parent.querySelectorAll(".upsy-plugin");
	if (upsyWidgets.length) {
		upsyWidgets.forEach(widget => {
			if (widget.parentNode) {
				widget.parentNode.removeChild(widget);
			}
		});
	}
};
