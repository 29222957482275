<script lang="ts">
	import { variantEnum } from "../../types/variant";
	import variantType from "../../types/variant";
	export let btnText: string;
	export let variant: variantType = variantEnum.MINIFIED;
	let classZ = "";
	export { classZ as class };
</script>

<button
	on:click
	class={`${
		variant === variantEnum.MINIFIED
			? "u-h-[27.61px] u-w-[70px]"
			: "u-h-[33px] u-w-[72px]"
	} u-px-1 u-text-[10px] min-w-390:u-text-[12px] u-word-wrap-break-word u-overflow-hidden u-font-medium u-cursor-pointer u-justify-center u-items-center u-border-none u-rounded-lg u-bg-accent u-text-white ${classZ}`}>
	<span class="u-line-clamp-2">
		{btnText}
	</span>
</button>
