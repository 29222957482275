import { get } from "svelte/store";
import { init } from "./sdk/init";
import { preview } from "./sdk/preview";
import { isSdkLoaded } from "./stores/sdkLoaded";
import { pageTypeEnum, setPageType } from "./utils/pageType";
import { enumToObject } from "./utils/utils";

window.upsy_sdk = {
	init,
	preview,
	pageTypes: enumToObject(pageTypeEnum),
	setPageType: (pageType: string) =>
		setPageType(pageType, Object.values(enumToObject(pageTypeEnum))),
	isLoaded: () => get(isSdkLoaded)
};
