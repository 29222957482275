<style>
	.u-rounded-inherit {
		border-radius: inherit;
	}
</style>

<script lang="ts">
	import { storeConfig } from "../../stores/storeConfig";
	import IPositionProps from "../../types/positionProps";
	import getPositionPropsToCss from "../../utils/positionPropsToCss";
	import { ISwipePayload, swipeAction } from "../../actions/swipeAction";
	import { swipeGreetingMiniWidget } from "../../utils/widgetSwipe";
	import { createEventDispatcher } from "svelte";
	import ChatNavigateButton from "./ChatNavigateButton.svelte";
	import LeftArrow from "./LeftArrow.svelte";
	import RightArrow from "./RightArrow.svelte";
	import { activePage } from "../../stores/activePage";
	import { pageTypeEnum } from "../../utils/pageType";
	import { getSideBarCtaText } from "../../utils/getUpsyText";
	import { upsyContext } from "../../stores/upsyContext";
	import { isProductsLoadingSpinner } from "../../stores/loadingSpinner";
	const dispatch = createEventDispatcher();
	let widgetToLeft: boolean | undefined;
	export let positionProps: IPositionProps;
	let classZ = "";
	export { classZ as class };
	export let asStandAloneComponent = false;
	$: widgetToLeft = $storeConfig.widgetToLeft;
	$: avatar = $storeConfig.avatar;
	$: bgColor = $storeConfig.buttonColor || "#189DEB";
	$: dynamicStyles = `${getPositionPropsToCss(positionProps)}`;

	const sideBarCTAEnabledPages = new Set([
		pageTypeEnum.CATEGORY,
		pageTypeEnum.PRODUCT,
		pageTypeEnum.FRONT
	]);

	// Display sidebar cta for specific pages and only if asStandAloneComponent value is true
	$: isDisplaySidebarCTA =
		sideBarCTAEnabledPages.has($activePage?.pageType as pageTypeEnum) &&
		asStandAloneComponent;

	function swipeHandler(e: CustomEvent) {
		const detail = e.detail as ISwipePayload;
		const { swipeDirection } = detail;
		swipeDirection && swipeGreetingMiniWidget(swipeDirection, widgetToLeft, dispatch);
	}
</script>

{#if isDisplaySidebarCTA}
	<div
		use:swipeAction
		on:swipe={swipeHandler}
		on:click
		style="background-color:{bgColor};--height:220px;--width:25px;{dynamicStyles}"
		class="u-cursor-pointer u-h-[var(--height)] u-w-[var(--width)] u-flex u-justify-center u-items-center u-flex-col-reverse u-shadow-[0_6px_12px_rgba(0,0,0,0.1)] u-select-none u-rounded-default">
		<ChatNavigateButton on:chatNavigate {bgColor} isCTABarLift={true} />

		<p
			class="u-px-1 u-line-clamp-1 u-w-[var(--height)] u-font-inter u-text-center u-font-semibold u-uppercase u-text-white u-text-xs u-tracking-[0.03em] u-rotate-[270deg]">
			{$getSideBarCtaText($upsyContext, $activePage?.pageType)}
		</p>
	</div>
{:else}
	<div
		use:swipeAction
		on:swipe={swipeHandler}
		on:click
		style="background-color:{bgColor};{dynamicStyles}"
		class={`u-invisible sm:u-visible u-touch-none u-flex u-justify-center u-items-center u-drop-shadow-welcome u-cursor-pointer u-bottom-1 u-right-1 u-w-[60px] u-h-[60px] sm:u-w-[63px] sm:u-h-[65px] ${classZ} ${
			widgetToLeft
				? "u-rounded-tr-[50%] u-rounded-tl-[8%] u-rounded-br-[50%] u-rounded-bl-[8%]"
				: "u-rounded-tl-[50%] u-rounded-tr-[8%] u-rounded-bl-[50%] u-rounded-br-[8%]"
		}`}>
		<ChatNavigateButton on:chatNavigate {bgColor} />
		<div
			class="u-bg-accent-light u-rounded-inherit u-w-[82%] u-h-[82%] u-bg-center u-bg-cover u-invisible sm:u-visible"
			style={avatar
				? `background-image:url('${avatar
						.replace(/'/g, "%27")
						.replace(/"/g, "%22")}')`
				: ``} />
	</div>
	{#if !$isProductsLoadingSpinner}
		<div
			use:swipeAction
			on:swipe={swipeHandler}
			on:click
			style={dynamicStyles}
			class={`u-visible sm:u-invisible u-touch-none u-flex u-justify-center u-items-center u-drop-shadow-welcome u-cursor-pointer u-bottom-1 u-right-1 u-w-[23px] u-h-[60px] ${classZ} ${
				widgetToLeft
					? "u-rounded-tr-[90%] u-rounded-tl-[8%] u-rounded-br-[50%] u-rounded-bl-[8%]"
					: "u-rounded-tl-[90%] u-rounded-tr-[8%] u-rounded-bl-[50%] u-rounded-br-[8%]"
			}`}>
			{#if widgetToLeft}
				<RightArrow />
			{:else}
				<LeftArrow />
			{/if}
			<ChatNavigateButton on:chatNavigate {bgColor} />
		</div>
	{/if}
{/if}
