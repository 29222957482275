//TODO:As current(2024-05-29) implementation of chatra there have no way to listen close event so its impossible to hide chat on close
const getChatButton = () => {
	return document.querySelector("button.chatra-button");
};

const moveChat = () => {
	const chatButton = getChatButton();
	if (!chatButton) {
		setTimeout(moveChat, 200);
		return;
	}
	chatButton.setAttribute("style", "display:none !important");
};
export const openChat = (closeWidget: () => void) => {
	const chatButton = getChatButton();
	if (!chatButton) {
		console.warn("sorry! chatara chat button not found");
		return;
	}
	closeWidget && typeof closeWidget == "function" && closeWidget();
	(chatButton as HTMLElement).click();
};

export const initializeChat = () => {
	moveChat();
};
