import { SwipeDirection } from "../actions/swipeAction";
import { Dispatch } from "../types/svelteEventDispatch";

export const swipeGreetingMiniWidget = (
	swipeDirection: string,
	widgetToLeft: boolean | undefined,
	dispatch: Dispatch
) => {
	if (swipeDirection === SwipeDirection.LEFT) {
		!widgetToLeft ? dispatch("minimize") : dispatch("close");
	} else if (swipeDirection === SwipeDirection.RIGHT) {
		!widgetToLeft ? dispatch("close") : dispatch("minimize");
	} else if (swipeDirection === SwipeDirection.TOP) {
		dispatch("minimize");
	}
};

export const swipeWidgetMinifiedWidget = (swipeDirection: string, dispatch: Dispatch) => {
	if (swipeDirection === SwipeDirection.TOP) {
		dispatch("expand");
	} else if (swipeDirection === SwipeDirection.BOTTOM) {
		dispatch("close");
	}
};

export const swipeWidgetExpanded = (swipeDirection: string, dispatch: Dispatch) => {
	if (swipeDirection === SwipeDirection.BOTTOM) {
		dispatch("minimize");
	}
};
