<script lang="ts">
	import { t } from "../../i18n/i18n";
	import { storeConfig } from "../../stores/storeConfig";
	import { getContext } from "svelte";
	import IPositionProps from "../../types/positionProps";
	import getPositionPropsToCss from "../../utils/positionPropsToCss";
	import { GlobalContextKeyEnum } from "../../types/context";
	import { activeWidgetScreen } from "../../stores/activeWidgetScreen";
	import { activeWidgetScreenEnum } from "../../types/activeWidgetScreen";
	import { IConfigObject } from "../../types/sdk";
	import { postFeedback } from "../../utils/event";

	export let previewMode = false;
	$: bgColor = $storeConfig.buttonColor || "#189DEB";
	$: widgetToLeft = $storeConfig.widgetToLeft;
	let config: IConfigObject;
	$: config = $storeConfig;
	let positiveFeeback: string;
	let negativeFeedback: string;
	$: positiveFeeback = $t("feedback_positiveFeedback");
	$: negativeFeedback = $t("feedback_negativeFeedback");

	let detailedMessageValue: string;
	let likeValue: boolean;
	let ts: string;
	let feedbackPhase = 0;
	export let textInBubble = $t("feedback_textInBubble");

	const environment = getContext<string>(GlobalContextKeyEnum.ENVIRONMENT);

	function reactionHandler() {
		if (!ts) {
			ts = new Date().toISOString();
		}
		!previewMode &&
			postFeedback(config, environment, {
				like: likeValue,
				ts,
				detailedMessage: detailedMessageValue
			});
		activeWidgetScreen.update(() => activeWidgetScreenEnum.THANKYOU);
	}

	function likeHandler(like: boolean) {
		if (!ts) {
			ts = new Date().toISOString();
		}
		feedbackPhase = 1;
		likeValue = like;
		!previewMode && postFeedback(config, environment, { like, ts });
	}

	export let positionProps: IPositionProps;
	$: propsTocss = getPositionPropsToCss(positionProps);
</script>

<div
	style={propsTocss}
	class={`${
		feedbackPhase == 1 ? "u-h-[210px]" : "u-h-[145px]"
	} u-w-[337px] u-max-w-full u-relative u-flex u-justify-center u-items-center u-flex-col u-drop-shadow-welcome`}>
	<svg
		class={`-u-z-1 u-absolute u-top-0 u-left-0 u-w-full u-h-full ${
			widgetToLeft ? " u-rotate-y-180 " : ""
		}`}
		height="100%"
		width="100%"
		viewBox="0 0 337 145"
		preserveAspectRatio="none"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M53.063 24.7974C33.3958 24.4183 22.5644 22.8271 5 8.52942C5 8.52942 10.7146 28.3585 13.0105 41.9692C16.9605 65.386 15.3878 88.518 18.2006 112.464C22.0223 145 42.7327 145 42.7327 145H300.387C349.952 145 348.45 22.9899 300.387 24.7974H53.063Z"
			fill="white" />
		<path
			d="M48.063 16.0927C28.3958 15.7177 17.5644 14.1436 0 0C0 0 5.71461 19.6154 8.0105 33.0795C11.9605 56.2439 10.3878 79.1266 13.2006 102.815C17.0223 135 37.7327 135 37.7327 135H295.387C344.952 135 343.45 14.3046 295.387 16.0927H48.063Z"
			fill={bgColor} />
	</svg>

	<div
		class="u-font-inter u-text-base u-leading-5 u-font-medium u-absolute u-px-8 u-w-full u-flex u-flex-col u-mt-2  u-items-center u-justify-center u-gap-[6px] ">
		{#if feedbackPhase != 1}
			<p class="u-text-white u-line-clamp-2">
				{textInBubble}
			</p>
		{/if}
		{#if feedbackPhase < 2}
			<p class=" u-w-1/3 u-flex u-justify-between u-items-center">
				<svg
					on:click={() => likeHandler(true)}
					class="u-cursor-pointer"
					width="36"
					height="36"
					viewBox="0 0 36 36"
					fill="none"
					opacity={feedbackPhase > 0 && likeValue !== true ? "40%" : "100%"}
					xmlns="http://www.w3.org/2000/svg">
					<circle cx="18" cy="18" r="18" fill="white" />
					<path
						d="M13.9961 18.9832V25.7729C13.9961 25.7729 14.0481 25.2413 17.0522 26.2502C17.0522 26.2502 19.1117 26.9999 20.6714 26.9999C20.6714 26.9999 22.6643 27.0001 23.7042 26.9999C24.7439 26.9999 25.0617 26.1751 25.4664 25.5209C25.4664 25.5209 25.8128 24.5831 25.4664 24.147C25.4664 24.147 26.4482 23.7739 26.5061 22.9763C26.5061 22.9763 26.6795 22.558 26.2751 21.8488C26.2751 21.8488 26.7949 21.2144 26.9104 20.1676C26.9104 20.1676 27.0837 19.8504 26.5636 19.4305C26.5636 19.4305 27.1968 18.1927 26.9378 17.5382C26.9378 17.5382 26.5636 16.2837 25.8126 16.3383C25.8126 16.3383 21.7688 16.5563 21.4802 16.611C21.1917 16.6657 21.0357 16.6654 20.4782 16.3383C20.4782 16.3383 20.2093 16.0109 21.018 14.9202C21.018 14.9202 22.0002 13.0662 22.2311 12.0298C22.2311 12.0298 22.5204 10.2301 20.9602 9.19394C20.9602 9.19394 20.2092 8.75758 19.7471 9.19394C19.7471 9.19394 19.6316 9.41231 19.5161 10.9393C19.5161 10.9393 19.4005 12.1391 18.534 13.175C18.534 13.175 16.7053 14.702 16.1854 15.9563C16.1855 15.9562 15.3704 17.92 13.9961 18.983L13.9961 18.9832Z"
						fill="#707070" />
					<path
						d="M9.11893 18.5509C8.55496 23.1154 10.1799 26.259 10.1799 26.259H13.4084V18.5555C11.4451 18.3219 9.11886 18.5508 9.11886 18.5508L9.11893 18.5509ZM11.893 25.3856C11.5587 25.3856 11.2877 25.1296 11.2877 24.814C11.2877 24.4985 11.5586 24.2425 11.893 24.2425C12.2272 24.2425 12.4984 24.4985 12.4984 24.814C12.4984 25.1296 12.2272 25.3856 11.893 25.3856Z"
						fill="#707070" />
				</svg>
				<svg
					on:click={() => likeHandler(false)}
					class="u-cursor-pointer"
					width="36"
					height="36"
					viewBox="0 0 36 36"
					fill="none"
					opacity={feedbackPhase > 0 && likeValue !== false ? "40%" : "100%"}
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="18"
						cy="18"
						r="18"
						transform="rotate(-180 18 18)"
						fill="white" />
					<path
						d="M22.0039 17.0168L22.0039 10.2271C22.0039 10.2271 21.9519 10.7587 18.9478 9.74979C18.9478 9.74979 16.8883 9.00006 15.3286 9.00006C15.3286 9.00006 13.3357 8.99993 12.2958 9.00006C11.2561 9.00006 10.9383 9.82492 10.5336 10.4791C10.5336 10.4791 10.1872 11.4169 10.5336 11.853C10.5336 11.853 9.55183 12.2261 9.49391 13.0237C9.49391 13.0237 9.32053 13.442 9.72495 14.1512C9.72495 14.1512 9.20508 14.7856 9.08963 15.8324C9.08963 15.8324 8.91625 16.1496 9.43639 16.5695C9.43639 16.5695 8.80325 17.8073 9.06224 18.4618C9.06224 18.4618 9.43639 19.7163 10.1874 19.6617C10.1874 19.6617 14.2312 19.4437 14.5198 19.389C14.8083 19.3343 14.9643 19.3346 15.5218 19.6617C15.5218 19.6617 15.7907 19.9891 14.982 21.0797C14.982 21.0797 13.9998 22.9338 13.7689 23.9702C13.7689 23.9702 13.4796 25.7699 15.0398 26.8061C15.0398 26.8061 15.7908 27.2424 16.2529 26.8061C16.2529 26.8061 16.3684 26.5877 16.4839 25.0607C16.4839 25.0607 16.5995 23.8609 17.466 22.825C17.466 22.825 19.2947 21.298 19.8146 20.0437C19.8145 20.0438 20.6296 18.08 22.0039 17.017L22.0039 17.0168Z"
						fill="#707070" />
					<path
						d="M26.8811 17.4491C27.445 12.8846 25.8201 9.74099 25.8201 9.74099L22.5916 9.74099L22.5916 17.4445C24.5549 17.6781 26.8811 17.4492 26.8811 17.4492L26.8811 17.4491ZM24.107 10.6144C24.4413 10.6144 24.7123 10.8704 24.7123 11.186C24.7123 11.5015 24.4414 11.7575 24.107 11.7575C23.7728 11.7575 23.5016 11.5015 23.5016 11.186C23.5016 10.8704 23.7728 10.6144 24.107 10.6144Z"
						fill="#707070" />
				</svg>
			</p>
		{/if}
		{#if feedbackPhase == 1}
			<div
				class=" u-block u-h-[73px] u-w-[273px] u-overflow-hidden u-rounded-[10px]">
				<textarea
					bind:value={detailedMessageValue}
					placeholder={likeValue ? positiveFeeback : negativeFeedback}
					class="u-font-inter u-font-normal u-h-full u-w-full u-text-left u-px-4  u-pt-3 u-pb-2  u-bg-white u-border-none focus:u-outline-accent" />
			</div>
			<button
				on:click={() => reactionHandler()}
				class=" u-cursor-pointer u-text-sm u-ml-[70%] u-border-none u-outline-none u-bg-accent-light u-text-accent u-rounded-lg u-px-4 u-py-2">
				{$t("submit")}
			</button>
		{/if}
	</div>
</div>
