/*
 ** Chat handler name : LiveChat
 ** Website: https://www.livechat.com/
 ** LiveChat API & SDK documentation: https://platform.text.com/docs/getting-started/
 ** LiveChat API & SDK documentation for javascript : https://platform.text.com/docs/extending-chat-widget/javascript-api
 */
const moveChat = () => {
	if (!window.LiveChatWidget) {
		setTimeout(moveChat, 200);
		return;
	} else {
		// Hide LiveChat initially becasue chat will be open via upsy
		window.LiveChatWidget.on("ready", () => {
			window.LiveChatWidget?.call("hide");
		});

		// Hide LiveChat when minimized, currently commented out this protion of code becasue assume,
		// very soon this feature request will come through
		// window.LiveChatWidget.on('visibility_changed', (data) => {
		// 	if (data && data.visibility == "minimized") {
		// 		window.LiveChatWidget?.call("hide");
		// 	}
		// });
	}
};

export const openChat = (closeWidget: () => void) => {
	window.LiveChatWidget?.call("maximize");
	closeWidget();
};

export const initializeChat = () => {
	moveChat();
};
