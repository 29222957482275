<script lang="ts">
	import { variantEnum } from "../../types/variant";
	import CloseIcon from "../shared/CloseIcon.svelte";
	import Header from "../shared/Header.svelte";
	import GreetingMini from "../shared/GreetingMini.svelte";
	import ProductCard from "../shared/ProductCard.svelte";
	import ShowBtn from "../shared/ShowBtn.svelte";
	import IPositionProps from "../../types/positionProps";
	import getPositionPropsToCss from "../../utils/positionPropsToCss";
	import { createEventDispatcher } from "svelte";
	import { upsyContext } from "../../stores/upsyContext";
	import { UpsyContextEnum } from "../../types/context";
	import CategoryCard from "../shared/CategoryCard.svelte";
	import { ifWidgetToLeftThenTransformPositions } from "../../utils/widgetPosition";
	import { isProductsLoadingSpinner } from "../../stores/loadingSpinner";
	import { upsyContexualProducts } from "../../stores/products";
	import { t } from "../../i18n/i18n";
	import { storeConfig } from "../../stores/storeConfig";
	import { ISwipePayload, swipeAction } from "../../actions/swipeAction";
	import { swipeWidgetMinifiedWidget } from "../../utils/widgetSwipe";
	import ProductSkeletonLoader from "../shared/ProductSkeletonLoader.svelte";
	import SwipeIndicator from "../shared/SwipeIndicator.svelte";
	import { getConditionalRadiusForExapandedOrMinifiedWidget } from "../../utils/utils";

	const dispatch = createEventDispatcher();

	function swipeHandler(e: CustomEvent) {
		const detail = e.detail as ISwipePayload;
		const { swipeDirection } = detail;
		swipeDirection && swipeWidgetMinifiedWidget(swipeDirection, dispatch);
	}
	export let previewMode = false;
	export let positionProps: IPositionProps;
	$: positionPropsToCss = getPositionPropsToCss({
		...positionProps,
		bottom: ($storeConfig.liftMinifiedWidget || "0px") as IPositionProps["bottom"]
	});

	$: widgetToLeft = $storeConfig.widgetToLeft;
	$: customerCategories = $storeConfig.customerCategories || [];
	$: conditionalRadius = getConditionalRadiusForExapandedOrMinifiedWidget(
		widgetToLeft,
		$storeConfig.liftMinifiedWidget
	);
	//A utility function to check if the widget is to the left or right. If it is to the left, it will transform the position props to the left.
	$: modifiedPositionPropsForGreetingMini = ifWidgetToLeftThenTransformPositions(
		widgetToLeft,
		{
			...positionProps,
			position: "absolute",
			bottom: "15px",
			right: "100%"
		}
	);

	$: btnText = $t("see_all");

	$: isNewProduct = $upsyContext === UpsyContextEnum.NEW_ARRIVAL ? true : false;
	$: isSaleProduct = $upsyContext === UpsyContextEnum.DAILY_DEALS ? true : false;

	function wheelHandler(
		evt: WheelEvent & { currentTarget: EventTarget & HTMLElement }
	) {
		evt.stopPropagation();
		evt.preventDefault();
		evt.currentTarget.scrollLeft +=
			Math.abs(evt.deltaX) > Math.abs(evt.deltaY) ? evt.deltaX : evt.deltaY;
	}
</script>

<!-- min-w-321:u-max-w-[calc(100%-68px)] -->
<div
	style={positionPropsToCss}
	class={`u-word-wrap-break-word u-touch-none u-font-inter u-fixed u-bg-background u-bottom-0 u-right-0 u-w-full desktop-device:u-h-[277px] mobile-and-table-device:u-h-[222px] sm:mobile-and-table-device:u-h-[268px] sm:desktop-device:u-h-[298px] min-w-321:u-max-w-[min(100%-26px,309px)]   min-w-390:u-max-w-[309px] sm:u-max-w-[326px] u-shadow-[0px_6px_12px_rgba(0,0,0,0.1)] ${conditionalRadius}`}>
	<div
		use:swipeAction
		on:swipe={swipeHandler}
		class="u-flex u-mx-6 u-relative desktop-device:u-py-[29.5px] mobile-and-table-device:u-py-[12px] mobile-and-table-device:sm:u-py-[15px]">
		<SwipeIndicator />
		<CloseIcon on:click={() => dispatch("close")} />
	</div>
	<div class="u-mx-4 sm:u-mx-6 u-flex u-justify-between u-items-start">
		<Header variant={variantEnum.MINIFIED} {dispatch} />
		<ShowBtn
			{btnText}
			class="u-hidden sm:u-flex"
			variant={variantEnum.MINIFIED}
			on:click={() => dispatch("expand")} />
	</div>
	<!-- Start of production or category loading area -->
	<div
		on:wheel={wheelHandler}
		class="u-overscroll-none u-hide-scroll u-ml-4 sm:u-ml-6 u-pr-2 u-py-2 sm:u-py-4 u-flex u-justify-start u-overflow-x-auto u-overflow-y-hidden u-gap-[6px] sm:u-gap-[10px]">
		{#if $upsyContext === UpsyContextEnum.CATEGORY}
			{#each customerCategories as category (category.order)}
				<CategoryCard {category} {previewMode} />
			{:else}
				<div
					class="u-w-full u-flex u-justify-center u-items-center u-h-[166px] sm:u-h-[168px]">
					<p class="u-text-center u-text-gray-600 u-text-sm">
						{$t("no_categories_found")}
					</p>
				</div>
			{/each}
		{:else if $isProductsLoadingSpinner}
			{#each [1, 2] as item (item)}
				<ProductSkeletonLoader />
			{/each}
		{:else}
			{#each $upsyContexualProducts as product (product.productId)}
				<ProductCard {product} {previewMode} {isNewProduct} {isSaleProduct} />
			{:else}
				<div
					class="u-w-full u-flex u-justify-center u-items-center u-h-[166px] sm:u-h-[168px]">
					<p class="u-text-center u-text-gray-600 u-text-sm">
						{$t("no_product_found")}!
					</p>
				</div>
			{/each}
		{/if}
	</div>
	<!-- End of production or category loading area -->
	<GreetingMini
		on:chatNavigate
		on:click={() => dispatch("close")}
		on:minimize={() => dispatch("minimize")}
		on:close={() => dispatch("close")}
		class="u-flex [&>div:nth-of-type(2)]:u-visible u-invisible sm:u-visible"
		positionProps={modifiedPositionPropsForGreetingMini} />
	<p class="u-absolute u-right-6 u-bottom-[0px] sm:u-invisible">
		<a
			href="https://upsyshopping.com/"
			target="_blank"
			class="u-no-underline u-text-xs u-font-extrabold u-text-gray-400">upsy</a>
	</p>
</div>
