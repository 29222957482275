import { IFB } from "../../types/externalLibraries";

let upsyFBCalledTimestamp = Date.now() - 5000;
const upsyFBAddiotionalDelays: number[] = [];

const upsyHideFB = function () {
	const fbRoot = document.getElementById("fb-root");
	if (fbRoot) {
		fbRoot.style.display = "none";
	}
};

const upsyShowFB = function () {
	const fbRoot = document.getElementById("fb-root");
	if (fbRoot) {
		fbRoot.style.display = "initial";
	}
};

const upsyMoveFBToLeft = function () {
	const styleSheet = document.createElement("style");
	styleSheet.innerText = "#fb-root iframe {left: 12px}";
	document.head.appendChild(styleSheet);
};

const callAfterFBFound = (callback: (FB: IFB) => void) => {
	if (!window.FB) {
		setTimeout(() => callAfterFBFound(callback), 500);
	} else {
		callback(window.FB);
	}
};

const upsyWaitForNextFBCallWindow = function (callback: (FB: IFB) => void, FB: IFB) {
	const delayLimit = 5000 + (upsyFBAddiotionalDelays.pop() || 0);
	const timeSinceLastCall = Date.now() - upsyFBCalledTimestamp;
	const delay = Math.max(0, delayLimit - timeSinceLastCall);
	console.log(`Upsy chat add-on: Waiting for a delay of ${delay}ms`);
	setTimeout(() => callback(FB), delay);
};

const upsyCallFB = (callback: (FB: IFB) => void) => {
	const innerCallback = function (FB: IFB) {
		callback(FB);
		upsyFBCalledTimestamp = Date.now();
	};
	callAfterFBFound((FB: IFB) => upsyWaitForNextFBCallWindow(innerCallback, FB));
};

export const openChat = (closeWidget: () => void) => {
	const callback = function (FB: IFB) {
		upsyShowFB();
		closeWidget();
		FB.CustomerChat.show(true);
	};
	upsyCallFB(callback);
	const hideCallback = function (FB: IFB) {
		upsyHideFB();
		FB.CustomerChat.hide();
	};
	const bindToDialogHideEvent = function (FB: IFB) {
		FB.Event.subscribe("customerchat.dialogHide", () => hideCallback(FB));
	};
	callAfterFBFound(bindToDialogHideEvent);
};

export const initializeChat = () => {
	const callback = function (FB: IFB) {
		FB.XFBML.parse(undefined, function () {
			upsyHideFB();
			upsyMoveFBToLeft();
		});
		upsyFBAddiotionalDelays.push(5000);
	};
	upsyCallFB(callback);
};
