import IPositionProps from "../types/positionProps";

export default function getPositionPropsToCss(positionProps: IPositionProps) {
	const { position, zIndex, bottom, right, left } = positionProps;
	return `${position ? `position:${position};` : ""}
             ${zIndex ? `z-index:${zIndex};` : ""}
             ${bottom ? `bottom:${bottom};` : ""}
             ${right ? `right:${right};` : ""}
             ${left ? `left:${left};` : ""}`
		.trim()
		.replace(/\s/g, "");
}
